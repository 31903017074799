import React from 'react';
import { ValidationError } from '../../types';
import { validateContactInfo, formatPhone } from '../../utils/validation/contactValidation';
import { AddressAutocomplete } from './AddressAutocomplete';
import { ContactValidation } from '../../types/validation';

interface CustomerFormProps {
  customerInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: string;
  };
  onChange: (field: string, value: string) => void;
  className?: string;
  errors?: ContactValidation;
  onValidate?: (field: keyof ContactValidation, error: string | undefined) => void;
}

const inputClasses = {
  label: "block text-sm font-medium text-gray-900 mb-2",
  input: "block w-full px-4 py-3 rounded-lg border-2 shadow-sm focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition-colors text-gray-900 text-lg",
  error: "text-sm text-red-600 mt-1",
  inputError: "border-red-300 focus:border-red-500",
  inputValid: "border-gray-200 focus:border-blue-500"
};

export const CustomerForm: React.FC<CustomerFormProps> = ({ 
  customerInfo, 
  onChange, 
  className = '',
  errors = {},
  onValidate
}) => {
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhone(e.target.value);
    onChange('phone', formattedPhone);
  };

  const handleBlur = (field: keyof ContactValidation) => {
    if (!onValidate) return;

    const validation = validateContactInfo(
      field === 'firstName' ? customerInfo.firstName : '',
      field === 'lastName' ? customerInfo.lastName : '',
      field === 'email' ? customerInfo.email : '',
      field === 'phone' ? customerInfo.phone : ''
    );

    onValidate(field, validation.errors[field]);
  };

  const getInputClassName = (field: keyof ContactValidation) => {
    return `${inputClasses.input} ${
      errors[field] ? inputClasses.inputError : inputClasses.inputValid
    }`;
  };

  return (
    <div className={className}>
      <h3 className="text-xl font-semibold text-gray-900 mb-6">Customer Information</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="firstName" className={inputClasses.label}>
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="firstName"
            value={customerInfo.firstName}
            onChange={(e) => onChange('firstName', e.target.value)}
            onBlur={() => handleBlur('firstName')}
            className={getInputClassName('firstName')}
            required
          />
          {errors.firstName && (
            <p className={inputClasses.error}>{errors.firstName}</p>
          )}
        </div>

        <div>
          <label htmlFor="lastName" className={inputClasses.label}>
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="lastName"
            value={customerInfo.lastName}
            onChange={(e) => onChange('lastName', e.target.value)}
            onBlur={() => handleBlur('lastName')}
            className={getInputClassName('lastName')}
            required
          />
          {errors.lastName && (
            <p className={inputClasses.error}>{errors.lastName}</p>
          )}
        </div>

        <div>
          <label htmlFor="email" className={inputClasses.label}>
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            value={customerInfo.email}
            onChange={(e) => onChange('email', e.target.value)}
            onBlur={() => handleBlur('email')}
            className={getInputClassName('email')}
            required
          />
          {errors.email && (
            <p className={inputClasses.error}>{errors.email}</p>
          )}
        </div>

        <div>
          <label htmlFor="phone" className={inputClasses.label}>
            Phone <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            id="phone"
            value={customerInfo.phone}
            onChange={handlePhoneChange}
            onBlur={() => handleBlur('phone')}
            className={getInputClassName('phone')}
            placeholder="(123) 456-7890"
            required
          />
          {errors.phone && (
            <p className={inputClasses.error}>{errors.phone}</p>
          )}
        </div>

        <div className="md:col-span-2">
          <AddressAutocomplete
            value={customerInfo.address}
            onChange={(value) => onChange('address', value)}
            onAddressSelect={(place) => {
              if (place.formatted_address) {
                onChange('address', place.formatted_address);
                if (onValidate) {
                  onValidate('address', undefined);
                }
              }
            }}
            error={errors.address}
          />
        </div>
      </div>
    </div>
  );
};