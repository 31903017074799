import { StyleSheet } from '@react-pdf/renderer';

export const pdfStyles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Helvetica',
    backgroundColor: '#f8fafc',
  },
  content: {
    flex: 1,
  },
  card: {
    backgroundColor: '#ffffff',
    padding: 20,
    marginBottom: 20,
    border: 1,
    borderColor: '#e2e8f0',
  },
  highlightCard: {
    backgroundColor: '#f0f9ff',
    padding: 20,
    marginBottom: 20,
    border: 1,
    borderColor: '#bfdbfe',
  },
  section: {
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#1e293b',
    marginBottom: 20,
    borderBottom: 1,
    borderBottomColor: '#e2e8f0',
    paddingBottom: 10,
  },
  subsectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#1e293b',
    marginBottom: 15,
  },
  coverTitle: {
    fontSize: 32,
    fontWeight: 'bold',
    color: '#1e293b',
    marginBottom: 20,
    width: '100%',
    maxWidth: 500,
  },
  coverSubtitle: {
    fontSize: 18,
    color: '#64748b',
    marginBottom: 40,
  },
  coverInfo: {
    fontSize: 14,
    color: '#64748b',
    marginBottom: 8,
  },
  statsGrid: {
    flexDirection: 'row',
    marginBottom: 20,
    gap: 15,
  },
  statCard: {
    flex: 1,
    padding: 15,
    backgroundColor: '#ffffff',
    border: 1,
    borderColor: '#e2e8f0',
  },
  statTitle: {
    fontSize: 12,
    color: '#64748b',
    marginBottom: 5,
  },
  statValue: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#1e293b',
  },
  statSubtext: {
    fontSize: 10,
    color: '#64748b',
    marginTop: 3,
  },
  applianceRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#e2e8f0',
  },
  applianceMain: {
    flex: 1,
  },
  applianceNameRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  applianceName: {
    fontSize: 11,
    color: '#1e293b',
    fontWeight: 'bold',
    marginLeft: 8,
  },
  applianceSpecs: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
  },
  specText: {
    fontSize: 10,
    color: '#64748b',
  },
  specDot: {
    fontSize: 10,
    color: '#94a3b8',
    marginHorizontal: 4,
  },
  applianceQuantity: {
    marginLeft: 20,
  },
  quantityText: {
    fontSize: 11,
    color: '#3b82f6',
    fontWeight: 'bold',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  label: {
    width: 180,
    fontSize: 12,
    color: '#64748b',
  },
  value: {
    flex: 1,
    fontSize: 12,
    color: '#1e293b',
    fontWeight: 'bold',
  },
  table: {
    marginTop: 15,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e2e8f0',
    paddingVertical: 12,
  },
  tableHeader: {
    backgroundColor: '#f8fafc',
    fontWeight: 'bold',
  },
  col1: {
    flex: 2,
    fontSize: 11,
    color: '#1e293b',
  },
  col2: {
    flex: 1,
    fontSize: 11,
    color: '#1e293b',
    textAlign: 'right',
  },
  termsSection: {
    marginTop: 20,
  },
  termsParagraph: {
    fontSize: 11,
    color: '#1e293b',
    marginBottom: 12,
    lineHeight: 1.4,
  },
  featureIcon: {
    width: 12,
    height: 12,
    marginRight: 8,
    backgroundColor: '#3b82f6',
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  systemFeature: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    paddingLeft: 15,
  },
  featureText: {
    fontSize: 11,
    color: '#1e293b',
  },
});