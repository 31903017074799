import { ApplianceItem } from '../types';

export function calculatePeakPower(appliances: ApplianceItem[]): number {
  return appliances
    .filter(a => a.quantity > 0)
    .reduce((sum, appliance) => {
      // Convert amps to watts (P = V * I)
      const watts = appliance.amps * 120;
      return sum + (watts * appliance.quantity);
    }, 0);
}

export function calculateTotalAmps(appliances: ApplianceItem[]): number {
  return appliances
    .filter(a => a.quantity > 0)
    .reduce((sum, appliance) => 
      sum + (appliance.amps * appliance.quantity), 0);
}

export function calculateDailyUsage(appliances: ApplianceItem[]): number {
  return appliances
    .filter(a => a.quantity > 0)
    .reduce((sum, appliance) => {
      const dailyWattHours = appliance.wattHours * appliance.hoursPerDay * appliance.quantity;
      return sum + (dailyWattHours / 1000); // Convert to kWh
    }, 0);
}