interface SolarFactors {
  [key: string]: number;
}

const stateSolarFactors: SolarFactors = {
  // Average peak sun hours per day by state
  AZ: 6.5, CA: 5.8, FL: 5.2, TX: 5.6, NV: 6.4,
  // Default for other states
  DEFAULT: 4.0
};

export function calculateSolarProduction(systemSizeKw: number, state: string): number {
  const peakSunHours = stateSolarFactors[state] || stateSolarFactors.DEFAULT;
  const systemEfficiency = 0.8; // 80% system efficiency
  
  return systemSizeKw * peakSunHours * systemEfficiency;
}