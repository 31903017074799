import { useState, useEffect } from 'react';
import { Session } from '@supabase/supabase-js';
import { AuthService } from '../AuthService';

export function useSession() {
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AuthService.getSession().then((session) => {
      setSession(session);
      setLoading(false);
    });

    const { data: { subscription } } = AuthService.onAuthStateChange((session) => {
      setSession(session);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  return { session, loading };
}