import { ContactValidation, FormValidation } from '../../types/validation';

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePhone = (phone: string): boolean => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return phoneRegex.test(phone);
};

export const formatPhone = (phone: string): string => {
  const cleaned = phone.replace(/\D/g, '');
  if (cleaned.length === 0) return '';
  if (cleaned.length <= 3) return cleaned;
  if (cleaned.length <= 6) return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
};

export const validateContactInfo = (
  firstName: string,
  lastName: string,
  email: string,
  phone: string
): FormValidation => {
  const errors: ContactValidation = {};
  let isValid = true;

  if (!firstName.trim()) {
    errors.firstName = 'First name is required';
    isValid = false;
  }

  if (!lastName.trim()) {
    errors.lastName = 'Last name is required';
    isValid = false;
  }

  if (!email.trim()) {
    errors.email = 'Email is required';
    isValid = false;
  } else if (!validateEmail(email)) {
    errors.email = 'Please enter a valid email address';
    isValid = false;
  }

  if (!phone.trim()) {
    errors.phone = 'Phone number is required';
    isValid = false;
  } else if (!validatePhone(phone)) {
    errors.phone = 'Please enter a valid phone number';
    isValid = false;
  }

  return { isValid, errors };
};