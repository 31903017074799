import React from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon, BoltIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

interface CreateProposalModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateProposalModal: React.FC<CreateProposalModalProps> = ({
  isOpen,
  onClose
}) => {
  const navigate = useNavigate();

  const handleSelectBattery = () => {
    onClose();
    navigate('/proposals/battery/new');
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm w-full bg-white rounded-xl shadow-xl">
          <div className="flex justify-between items-center p-6 border-b border-gray-200">
            <Dialog.Title className="text-xl font-bold text-gray-900">
              Create New Proposal
            </Dialog.Title>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          <div className="p-6">
            <button
              onClick={handleSelectBattery}
              className="w-full p-4 flex items-center justify-between bg-gray-50 hover:bg-blue-50 rounded-lg border-2 border-gray-200 hover:border-blue-200 transition-all"
            >
              <div className="flex items-center">
                <div className="w-10 h-10 bg-blue-100 rounded-lg flex items-center justify-center mr-4">
                  <BoltIcon className="w-6 h-6 text-blue-600" />
                </div>
                <div className="text-left">
                  <h3 className="font-medium text-gray-900">Battery Backup</h3>
                  <p className="text-sm text-gray-500">Create a battery backup system quote</p>
                </div>
              </div>
              <div className="text-blue-600">→</div>
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};