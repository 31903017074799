import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { QuoteDetails } from '../../types';
import { pdfStyles } from './styles';

interface SystemRequirementsProps {
  quoteDetails: QuoteDetails;
}

export const SystemRequirements: React.FC<SystemRequirementsProps> = ({ quoteDetails }) => {
  const peakKw = (quoteDetails.totalAmps * 120) / 1000;

  return (
    <View style={pdfStyles.section}>
      <Text style={pdfStyles.sectionTitle}>System Requirements</Text>
      
      <View style={pdfStyles.statsGrid}>
        <View style={[pdfStyles.statCard, { backgroundColor: '#eff6ff' }]}>
          <Text style={pdfStyles.statTitle}>Total Load</Text>
          <Text style={pdfStyles.statValue}>{quoteDetails.totalAmps.toFixed(1)} Amps</Text>
          <Text style={pdfStyles.statSubtext}>Peak current draw</Text>
        </View>

        <View style={[pdfStyles.statCard, { backgroundColor: '#fef2f2' }]}>
          <Text style={pdfStyles.statTitle}>Peak Usage</Text>
          <Text style={pdfStyles.statValue}>{peakKw.toFixed(1)} kW</Text>
          <Text style={pdfStyles.statSubtext}>Continuous power needed</Text>
        </View>

        <View style={[pdfStyles.statCard, { backgroundColor: '#f0fdf4' }]}>
          <Text style={pdfStyles.statTitle}>Daily Usage</Text>
          <Text style={pdfStyles.statValue}>{quoteDetails.dailyUsage.toFixed(1)} kWh</Text>
          <Text style={pdfStyles.statSubtext}>Energy consumed per day</Text>
        </View>

        {quoteDetails.solarDetails?.hasSolar && (
          <View style={[pdfStyles.statCard, { backgroundColor: '#fefce8' }]}>
            <Text style={pdfStyles.statTitle}>Solar Production</Text>
            <Text style={pdfStyles.statValue}>
              {quoteDetails.solarDetails.estimatedDailyProduction?.toFixed(1)} kWh
            </Text>
            <Text style={pdfStyles.statSubtext}>Daily solar offset</Text>
          </View>
        )}
      </View>

      <Text style={[pdfStyles.subsectionTitle, { marginTop: 30 }]}>Selected Appliances</Text>
      <View style={pdfStyles.card}>
        {quoteDetails.appliances.map((appliance, index) => (
          <View 
            key={index} 
            style={[
              pdfStyles.applianceRow,
              index === quoteDetails.appliances.length - 1 ? { borderBottomWidth: 0 } : {}
            ]}
          >
            <View style={pdfStyles.applianceMain}>
              <View style={pdfStyles.applianceNameRow}>
                <View style={pdfStyles.featureIcon} />
                <Text style={pdfStyles.applianceName}>{appliance.name}</Text>
              </View>
              <View style={pdfStyles.applianceSpecs}>
                <Text style={pdfStyles.specText}>{appliance.wattHours}W</Text>
                <Text style={pdfStyles.specDot}>•</Text>
                <Text style={pdfStyles.specText}>{appliance.amps}A</Text>
                <Text style={pdfStyles.specDot}>•</Text>
                <Text style={pdfStyles.specText}>{appliance.hoursPerDay} hrs/day</Text>
              </View>
            </View>
            <View style={pdfStyles.applianceQuantity}>
              <Text style={pdfStyles.quantityText}>
                {appliance.quantity}x ({(appliance.amps * appliance.quantity).toFixed(1)}A)
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};