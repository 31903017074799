import React from 'react';
import { ApplianceItem, SolarDetails } from '../types';
import { BoltIcon, SunIcon, ClockIcon, BoltSlashIcon } from '@heroicons/react/24/outline';

export interface SelectedAppliancesSummaryProps {
  appliances: ApplianceItem[];
  solarDetails?: SolarDetails | null;
}

export const SelectedAppliancesSummary: React.FC<SelectedAppliancesSummaryProps> = ({
  appliances,
  solarDetails
}) => {
  const selectedAppliances = appliances.filter(a => a.quantity > 0);
  
  const totalAmps = selectedAppliances.reduce((sum, appliance) => 
    sum + (appliance.amps * appliance.quantity), 0
  );

  const peakKw = (totalAmps * 120) / 1000; // Convert amps to kW (P = V * I)

  const dailyUsageKwh = selectedAppliances.reduce((sum, appliance) => 
    sum + (appliance.wattHours * appliance.hoursPerDay * appliance.quantity / 1000), 0
  );

  const solarProduction = solarDetails?.estimatedDailyProduction || 0;
  const netDailyUsage = Math.max(0, dailyUsageKwh - solarProduction);
  const solarCoveragePercent = solarProduction > 0 
    ? Math.min(100, Math.round((solarProduction / dailyUsageKwh) * 100))
    : 0;

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 border border-gray-100">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Your Home Requirements</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-blue-50 rounded-lg p-4">
          <div className="flex items-center space-x-2 mb-1">
            <BoltIcon className="h-5 w-5 text-blue-600" />
            <p className="font-medium text-blue-900">Total Load</p>
          </div>
          <p className="text-2xl font-bold text-blue-900">{totalAmps.toFixed(1)} Amps</p>
          <p className="text-sm text-blue-600">Peak current draw</p>
        </div>

        <div className="bg-red-50 rounded-lg p-4">
          <div className="flex items-center space-x-2 mb-1">
            <BoltSlashIcon className="h-5 w-5 text-red-600" />
            <p className="font-medium text-red-900">Peak Usage</p>
          </div>
          <p className="text-2xl font-bold text-red-900">{peakKw.toFixed(1)} kW</p>
          <p className="text-sm text-red-600">Continuous power needed</p>
        </div>

        <div className="bg-green-50 rounded-lg p-4">
          <div className="flex items-center space-x-2 mb-1">
            <ClockIcon className="h-5 w-5 text-green-600" />
            <p className="font-medium text-green-900">Daily Usage</p>
          </div>
          <p className="text-2xl font-bold text-green-900">{dailyUsageKwh.toFixed(1)} kWh</p>
          <p className="text-sm text-green-600">Energy consumed per day</p>
        </div>

        <div className="bg-yellow-50 rounded-lg p-4">
          <div className="flex items-center space-x-2 mb-1">
            <SunIcon className="h-5 w-5 text-yellow-600" />
            <p className="font-medium text-yellow-900">Solar Production</p>
          </div>
          <p className="text-2xl font-bold text-yellow-900">
            {solarDetails?.hasSolar 
              ? `${solarProduction.toFixed(1)} kWh` 
              : 'No Solar'
            }
          </p>
          {solarDetails?.hasSolar ? (
            <p className="text-sm text-yellow-600">
              Covers {solarCoveragePercent}% of daily usage
            </p>
          ) : (
            <p className="text-sm text-yellow-600">
              Solar not installed
            </p>
          )}
        </div>
      </div>

      <div className="space-y-4">
        <h3 className="text-lg font-semibold text-gray-900">Selected Appliances</h3>
        {selectedAppliances.map((appliance, index) => (
          <div 
            key={index}
            className="flex items-center justify-between bg-gray-50 rounded-lg p-3"
          >
            <div>
              <p className="font-medium text-gray-900">{appliance.name}</p>
              <div className="flex flex-wrap gap-2 mt-1">
                <span className="text-sm text-gray-500">{appliance.wattHours}W</span>
                <span className="text-sm text-gray-500">•</span>
                <span className="text-sm text-gray-500">{appliance.amps}A</span>
                <span className="text-sm text-gray-500">•</span>
                <span className="text-sm text-gray-500">{appliance.hoursPerDay} hrs/day</span>
              </div>
            </div>
            <div className="text-sm font-medium text-blue-600">
              {appliance.quantity}x ({(appliance.amps * appliance.quantity).toFixed(1)}A)
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};