import React, { useCallback } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useDebounce } from '../../hooks/useDebounce';

interface ProposalSearchProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
}

export const ProposalSearch: React.FC<ProposalSearchProps> = ({
  searchTerm,
  onSearchChange
}) => {
  const debouncedSearch = useDebounce(onSearchChange, 300);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onSearchChange(value); // Update the visible input immediately
    debouncedSearch(value); // Debounce the actual search
  }, [onSearchChange, debouncedSearch]);

  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
      </div>
      <input
        type="text"
        value={searchTerm}
        onChange={handleChange}
        className="block w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        placeholder="Search by customer name, address, or phone..."
      />
    </div>
  );
};