import React from 'react';
import { BoltIcon } from '@heroicons/react/24/outline';
import { batterySpecs } from '../data/batterySpecs';

export interface BatteryManagerProps {
  baseBatteries: number;
  extraBatteries: number;
  inverterCount: number;
  onUpdateBatteries?: (increment: boolean) => void;
  showCapacity?: boolean;
}

export const BatteryManager: React.FC<BatteryManagerProps> = ({
  baseBatteries,
  extraBatteries,
  inverterCount,
  showCapacity = true
}) => {
  const totalBatteries = baseBatteries + extraBatteries;
  const totalCapacity = totalBatteries * batterySpecs.energyCapacity;

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <BoltIcon className="h-5 w-5 text-blue-500" />
          <span className="font-medium text-gray-900">
            Batteries: {totalBatteries}
          </span>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 justify-center">
        {Array.from({ length: totalBatteries }).map((_, index) => (
          <img
            key={index}
            src="https://ironhorsegear.com/cdn/shop/products/battery.jpg?v=1613684124"
            alt="Battery Pack"
            className="h-24 w-auto object-contain rounded-lg border border-gray-200"
          />
        ))}
      </div>

      {showCapacity && (
        <div className="text-sm text-gray-600">
          <div>Total Capacity: {totalCapacity.toFixed(1)} kWh</div>
          <div className="text-xs text-gray-500 mt-1">
            {totalBatteries} batter{totalBatteries !== 1 ? 'ies' : 'y'} with {inverterCount} inverter{inverterCount > 1 ? 's' : ''}
          </div>
        </div>
      )}
    </div>
  );
};