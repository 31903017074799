import { Json } from '../../database.types';
import { CustomerInfo, QuoteDetails, SystemTier, ApplianceItem } from '../../../../types';

interface JsonObject {
  [key: string]: Json | undefined;
}

function isJsonObject(data: Json | undefined): data is JsonObject {
  return typeof data === 'object' && data !== null && !Array.isArray(data);
}

function transformApplianceItem(data: Json): ApplianceItem {
  if (!isJsonObject(data)) {
    throw new Error('Invalid appliance data format');
  }

  return {
    name: String(data.name || ''),
    wattHours: Number(data.wattHours || 0),
    hoursPerDay: Number(data.hoursPerDay || 0),
    kwhPerDay: Number(data.kwhPerDay || 0),
    quantity: Number(data.quantity || 0),
    amps: Number(data.amps || 0)
  };
}

export function transformCustomerInfo(data: Json): CustomerInfo {
  if (!isJsonObject(data)) {
    throw new Error('Invalid customer info format');
  }
  
  return {
    firstName: String(data.firstName || ''),
    lastName: String(data.lastName || ''),
    email: String(data.email || ''),
    phone: String(data.phone || ''),
    address: String(data.address || '')
  };
}

export function transformQuoteDetails(data: Json): QuoteDetails {
  if (!isJsonObject(data)) {
    throw new Error('Invalid quote details format');
  }

  const selectedSystem = data.selectedSystem;
  if (!isJsonObject(selectedSystem)) {
    throw new Error('Invalid selected system data');
  }

  const transformedSystem: SystemTier = {
    name: String(selectedSystem.name || ''),
    batteries: Number(selectedSystem.batteries || 0),
    inverters: Number(selectedSystem.inverters || 0),
    description: selectedSystem.description ? String(selectedSystem.description) : undefined,
    coverage: Array.isArray(selectedSystem.coverage) ? selectedSystem.coverage.map(String) : undefined,
    idealFor: Array.isArray(selectedSystem.idealFor) ? selectedSystem.idealFor.map(String) : undefined,
    backupHours: selectedSystem.backupHours ? Number(selectedSystem.backupHours) : undefined,
    amps: selectedSystem.amps ? Number(selectedSystem.amps) : undefined,
    price: selectedSystem.price ? Number(selectedSystem.price) : undefined
  };

  const appliances = Array.isArray(data.appliances) 
    ? data.appliances.map(transformApplianceItem)
    : [];

  return {
    selectedSystem: transformedSystem,
    extraBatteries: Number(data.extraBatteries || 0),
    totalPrice: Number(data.totalPrice || 0),
    backupHours: Number(data.backupHours || 0),
    dailyUsage: Number(data.dailyUsage || 0),
    totalAmps: Number(data.totalAmps || 0),
    appliances,
    solarDetails: data.solarDetails ? transformSolarDetails(data.solarDetails) : null
  };
}

function transformSolarDetails(data: Json): QuoteDetails['solarDetails'] {
  if (!isJsonObject(data)) {
    return null;
  }

  return {
    hasSolar: Boolean(data.hasSolar),
    systemSize: data.systemSize ? Number(data.systemSize) : undefined,
    state: String(data.state || ''),
    estimatedDailyProduction: data.estimatedDailyProduction ? 
      Number(data.estimatedDailyProduction) : undefined
  };
}