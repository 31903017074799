import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { QuoteDetails } from '../../types';
import { pdfStyles } from './styles';

const styles = StyleSheet.create({
  ...pdfStyles,
});

interface InvestmentSummaryProps {
  quoteDetails: QuoteDetails;
}

export const InvestmentSummary: React.FC<InvestmentSummaryProps> = ({ quoteDetails }) => (
  <View style={styles.section}>
    <Text style={styles.sectionTitle}>Investment Summary</Text>
    <View style={styles.card}>
      <View style={styles.tableRow}>
        <Text style={styles.col1}>Equipment and Installation</Text>
        <Text style={styles.col2}>${quoteDetails.totalPrice.toLocaleString()}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.col1}>Federal Tax Credit (30%)</Text>
        <Text style={styles.col2}>-${(quoteDetails.totalPrice * 0.3).toLocaleString()}</Text>
      </View>
      <View style={[styles.tableRow, styles.tableHeader]}>
        <Text style={styles.col1}>Final Investment</Text>
        <Text style={styles.col2}>
          ${(quoteDetails.totalPrice * 0.7).toLocaleString()}
        </Text>
      </View>
    </View>
  </View>
);