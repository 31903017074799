import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { StepperProvider } from '../contexts/StepperContext';
import { StepperLayout } from '../components/stepper/StepperLayout';
import { ApplianceStep, SolarStep, SystemStep, QuoteStep, ScheduleStep } from '../components/steps';
import { ApplianceItem, CalculationResult, SystemTier, SolarDetails, QuoteDetails } from '../types';
import { Step } from '../types/stepper';
import { calculateSystemRequirements } from '../utils/systemCalculator';
import { useScrollTop } from '../hooks/useScrollTop';
import { fetchAppliances } from '../lib/supabase/queries/appliances';
import { useStepper } from '../hooks/useStepper';

const steps: Step[] = [
  { name: "Select Appliances", description: "Choose your backup items" },
  { name: "Solar Evaluation", description: "Current solar setup" },
  { name: "Customize System", description: "Select your backup solution" },
  { name: "Quote Proposal", description: "Review and finalize" },
  { name: "Schedule Visit", description: "Book your consultation" }
];

const BatteryCalculatorContent: React.FC = () => {
  const { currentStep } = useStepper();
  const [appliances, setAppliances] = useState<ApplianceItem[]>([]);
  const [solarDetails, setSolarDetails] = useState<SolarDetails | null>(null);
  const [selectedSystem, setSelectedSystem] = useState<SystemTier | null>(null);
  const [extraBatteries, setExtraBatteries] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [backupHours, setBackupHours] = useState(0);
  const [showPreRebatePrice, setShowPreRebatePrice] = useState(false);

  useEffect(() => {
    const loadAppliances = async () => {
      const data = await fetchAppliances();
      setAppliances(data);
    };
    loadAppliances();
  }, []);

  const calculationResult = useMemo(() => {
    return calculateSystemRequirements(appliances, solarDetails?.estimatedDailyProduction || 0);
  }, [appliances, solarDetails]);

  const handleUpdateQuantity = useCallback((index: number, quantity: number) => {
    setAppliances(prev => prev.map((item, i) => 
      i === index ? { ...item, quantity } : item
    ));
  }, []);

  const handleSolarComplete = useCallback((details: SolarDetails) => {
    setSolarDetails(details);
  }, []);

  const handleSystemSelect = useCallback((
    system: SystemTier,
    extraBatteries: number,
    totalPrice: number,
    backupHours: number
  ) => {
    setSelectedSystem(system);
    setExtraBatteries(extraBatteries);
    setTotalPrice(totalPrice);
    setBackupHours(backupHours);
  }, []);

  const quoteDetails: QuoteDetails | undefined = selectedSystem ? {
    selectedSystem,
    extraBatteries,
    totalPrice,
    backupHours,
    dailyUsage: calculationResult.dailyUsage,
    totalAmps: calculationResult.totalAmps,
    appliances: calculationResult.systemKit.appliances,
    solarDetails
  } : undefined;

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <ApplianceStep
            appliances={appliances}
            onUpdateQuantity={handleUpdateQuantity}
          />
        );
      case 1:
        return (
          <SolarStep
            onComplete={handleSolarComplete}
            onBack={() => {}}
          />
        );
      case 2:
        return (
          <SystemStep
            result={calculationResult}
            solarDetails={solarDetails}
            onSystemSelect={handleSystemSelect}
            onUpdateAppliances={handleUpdateQuantity}
            showPreRebatePrice={showPreRebatePrice}
            onBack={() => {}}
          />
        );
      case 3:
        return quoteDetails && (
          <QuoteStep
            quoteDetails={quoteDetails}
            showPreRebatePrice={showPreRebatePrice}
            onBack={() => {}}
            onPriceToggle={setShowPreRebatePrice}
          />
        );
      case 4:
        return quoteDetails && (
          <ScheduleStep
            quoteDetails={quoteDetails}
            onBack={() => {}}
          />
        );
      default:
        return null;
    }
  };

  return (
    <StepperLayout
      steps={steps}
      showPriceToggle={currentStep >= 3}
      showPreRebatePrice={showPreRebatePrice}
      onPriceToggle={setShowPreRebatePrice}
    >
      {renderStep()}
    </StepperLayout>
  );
};

export const BatteryCalculator: React.FC = () => {
  return (
    <StepperProvider 
      totalSteps={steps.length} 
      steps={steps} 
      initialStep={0}
    >
      <BatteryCalculatorContent />
    </StepperProvider>
  );
};