import { ApplianceItem, SystemKit, SystemTier } from '../types';
import { batterySpecs } from '../data/batterySpecs';

export function createEmptySystemKit(): SystemKit {
  return {
    inverters: 0,
    batteries: 0,
    totalCapacity: 0,
    continuousPower: 0,
    peakPower: 0,
    maxSolarInput: 0,
    backupHours: 0,
    appliances: [],
    systemTiers: []
  };
}

export function createSystemKit(
  inverterCount: number,
  batteryCount: number,
  power: {
    continuousPower: number;
    peakPower: number;
    maxSolarInput: number;
  },
  backupHours: number,
  appliances: ApplianceItem[],
  systemTiers: SystemTier[]
): SystemKit {
  return {
    inverters: inverterCount,
    batteries: batteryCount,
    totalCapacity: batteryCount * batterySpecs.energyCapacity,
    continuousPower: power.continuousPower,
    peakPower: power.peakPower,
    maxSolarInput: power.maxSolarInput,
    backupHours,
    appliances: appliances.filter(a => a.quantity > 0),
    systemTiers
  };
}