import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useStepper } from '../../hooks/useStepper';
import { StepValidation } from '../../types/validation';

interface StepWrapperProps {
  children: React.ReactNode;
  validation: StepValidation;
  nextLabel?: string;
}

export const StepWrapper: React.FC<StepWrapperProps> = ({
  children,
  validation,
  nextLabel
}) => {
  const { updateStepValidation, setNextLabel } = useStepper();

  // Set next label only when it changes
  useEffect(() => {
    if (nextLabel) {
      setNextLabel(nextLabel);
    }
  }, [nextLabel, setNextLabel]);

  // Update validation only when validation changes
  useEffect(() => {
    updateStepValidation(validation.isValid, validation.errors);
  }, [validation.isValid, validation.errors, updateStepValidation]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
        className="space-y-6"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};