import { batterySpecs } from '../data/batterySpecs';

export function calculateRequiredInverters(totalAmps: number): number {
  const ampsPerInverter = batterySpecs.power.continuous * 1000 / 120;
  return Math.max(1, Math.ceil(totalAmps / ampsPerInverter));
}

export function calculateInverterCapacity(inverterCount: number) {
  return {
    continuousPower: inverterCount * batterySpecs.power.continuous,
    peakPower: inverterCount * batterySpecs.power.peak,
    maxSolarInput: inverterCount * batterySpecs.inverter.maxSolarInput,
  };
}