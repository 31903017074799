import React from 'react';
import { Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { CustomerInfo } from '../../types';
import { pdfStyles } from './styles';

interface CoverPageProps {
  customerInfo?: CustomerInfo;
}

const styles = StyleSheet.create({
  ...pdfStyles,
  coverPage: {
    backgroundColor: '#f8fafc',
    height: '100%',
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logo: {
    width: 200,
    marginBottom: 40,
  },
  coverContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
});

export const CoverPage: React.FC<CoverPageProps> = ({ customerInfo }) => (
  <Page size="A4" style={styles.page}>
    <View style={styles.coverPage}>
      <Image
        src="https://cdn.prod.website-files.com/67091f695cd8e90971357ea4/670c042366629d51d7d23454_maincolored.png"
        style={styles.logo}
      />
      <View style={styles.coverContent}>
        <Text style={styles.coverTitle}>Energy Storage System Quote</Text>
        <Text style={styles.coverSubtitle}>
          Custom Power Solution for {customerInfo ? `${customerInfo.firstName} ${customerInfo.lastName}` : 'Your Home'}
        </Text>
        {customerInfo && (
          <View>
            <Text style={styles.coverInfo}>{customerInfo.address}</Text>
            <Text style={styles.coverInfo}>{customerInfo.email}</Text>
            <Text style={styles.coverInfo}>{customerInfo.phone}</Text>
          </View>
        )}
      </View>
      <Text style={styles.coverInfo}>
        Proposal Date: {format(new Date(), 'MMMM d, yyyy')}
      </Text>
    </View>
  </Page>
);