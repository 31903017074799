import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { QuoteDetails, CustomerInfo } from '../types';
import { ProposalDocument } from './pdf/ProposalDocument';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';

interface ProposalPdfButtonProps {
  quoteDetails: QuoteDetails;
  customerInfo?: CustomerInfo;
}

export const ProposalPdfButton: React.FC<ProposalPdfButtonProps> = ({ quoteDetails, customerInfo }) => {
  const handleGeneratePdf = async () => {
    try {
      const blob = await pdf(
        <ProposalDocument 
          quoteDetails={quoteDetails}
          customerInfo={customerInfo}
        />
      ).toBlob();

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'energy-storage-system-quote.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <button
      onClick={handleGeneratePdf}
      className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
    >
      <DocumentArrowDownIcon className="h-5 w-5 mr-2" />
      Print Proposal
    </button>
  );
};