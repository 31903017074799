import React from 'react';
import { BoltIcon } from '@heroicons/react/24/outline';

interface InverterDisplayProps {
  inverterCount: number;
  showCapacity?: boolean;
}

export const InverterDisplay: React.FC<InverterDisplayProps> = ({
  inverterCount,
  showCapacity = true
}) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <BoltIcon className="h-5 w-5 text-yellow-500" />
          <span className="font-medium text-gray-900">
            Gen2 Inverters: {inverterCount}
          </span>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 justify-center">
        {Array.from({ length: inverterCount }).map((_, index) => (
          <img
            key={index}
            src="https://static.wixstatic.com/media/391809_40f94810a0ea45188feaf54be45ef3f6~mv2.webp/v1/fill/w_480,h_480,al_c,q_80,enc_auto/391809_40f94810a0ea45188feaf54be45ef3f6~mv2.webp"
            alt="Gen2 Inverter"
            className="h-24 w-auto object-contain rounded-lg border border-gray-200"
          />
        ))}
      </div>

      {showCapacity && (
        <div className="text-sm text-gray-600">
          Max Power: {(inverterCount * 8).toFixed(1)} kW continuous
        </div>
      )}
    </div>
  );
};