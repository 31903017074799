import React from 'react';
import { ApplianceItem } from '../types';

interface ApplianceListProps {
  appliances: ApplianceItem[];
  onUpdateQuantity: (index: number, quantity: number) => void;
}

export const ApplianceList: React.FC<ApplianceListProps> = ({ appliances, onUpdateQuantity }) => {
  const categories = {
    "Kitchen": ["Refrigerator", "Microwave", "Coffee Maker", "Dishwasher", "Electric oven", "Gas oven"],
    "Electronics": ["TV", "Cable/DVR Box", "Gaming Console", "Wi-Fi Router", "Laptop", "Desktop Computer", "Phone Charger"],
    "Lighting": ["LED Light"],
    "HVAC & Climate": ["Central AC", "Window AC", "Ceiling Fan", "Space Heater", "Electric furnace", "Gas furnace"],
    "Laundry": ["Washing Machine", "Electric Dryer", "Gas Dryer"],
    "Water & Plumbing": ["Electric Water Heater", "Well Pump", "Sump Pump"],
    "Safety & Security": ["Security System", "Garage Door Opener"],
    "Other": ["Dehumidifier"]
  };

  const getCategory = (applianceName: string) => {
    return Object.entries(categories).find(([_, items]) => 
      items.some(item => applianceName.toLowerCase().includes(item.toLowerCase()))
    )?.[0] || "Other";
  };

  const groupedAppliances = appliances.reduce((acc, appliance) => {
    const category = getCategory(appliance.name);
    if (!acc[category]) acc[category] = [];
    acc[category].push(appliance);
    return acc;
  }, {} as Record<string, ApplianceItem[]>);

  const categoryOrder = [
    "Kitchen",
    "Electronics",
    "Lighting",
    "HVAC & Climate",
    "Laundry",
    "Water & Plumbing",
    "Safety & Security",
    "Other"
  ];

  const sortedCategories = categoryOrder.filter(category => groupedAppliances[category]?.length > 0);

  return (
    <div className="mt-4 mb-24">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Select Your Appliances</h2>
      <div className="space-y-6">
        {sortedCategories.map((category) => (
          <div key={category} className="bg-white/90 backdrop-blur-sm rounded-lg p-4 shadow-sm border border-white/20">
            <h3 className="text-lg font-semibold text-gray-800 mb-3">{category}</h3>
            <div className="space-y-3">
              {groupedAppliances[category].map((appliance) => {
                const applianceIndex = appliances.indexOf(appliance);
                const totalAmps = appliance.amps * appliance.quantity;
                
                return (
                  <div 
                    key={applianceIndex} 
                    className={`flex items-center space-x-4 p-3 rounded-lg transition-colors ${
                      appliance.quantity > 0 ? 'bg-blue-50/90 backdrop-blur-sm border border-blue-100' : 'hover:bg-gray-50/90 hover:backdrop-blur-sm border border-transparent'
                    }`}
                  >
                    <div className="flex-1">
                      <p className="font-medium text-gray-900">{appliance.name}</p>
                      <div className="flex flex-wrap gap-2 mt-1 text-sm text-gray-500">
                        <span>{appliance.wattHours}W</span>
                        <span>•</span>
                        <span>{appliance.amps}A</span>
                        <span>•</span>
                        <span>{appliance.hoursPerDay} hrs/day</span>
                        <span>•</span>
                        <span>{appliance.kwhPerDay} kWh/day</span>
                        {appliance.quantity > 0 && (
                          <>
                            <span>•</span>
                            <span className="text-blue-600 font-medium">
                              Total: {totalAmps.toFixed(1)}A
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => onUpdateQuantity(applianceIndex, Math.max(0, appliance.quantity - 1))}
                        className="p-1 rounded-full hover:bg-blue-100 text-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={appliance.quantity === 0}
                      >
                        -
                      </button>
                      <span className="w-12 text-center font-medium text-gray-900">
                        {appliance.quantity}
                      </span>
                      <button
                        onClick={() => onUpdateQuantity(applianceIndex, appliance.quantity + 1)}
                        className="p-1 rounded-full hover:bg-blue-100 text-blue-600"
                      >
                        +
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};