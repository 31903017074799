import React, { useState, useEffect } from 'react';
import { SolarDetails } from '../types';
import { calculateSolarProduction } from '../utils/solarCalculator';
import { states } from '../data/states';
import { useStepper } from '../hooks/useStepper';

interface SolarEvaluationProps {
  onComplete: (details: SolarDetails) => void;
  onBack: () => void;
}

export const SolarEvaluation: React.FC<SolarEvaluationProps> = ({
  onComplete,
  onBack
}) => {
  const [hasSolar, setHasSolar] = useState<boolean | null>(true);
  const [systemSize, setSystemSize] = useState<string>('');
  const [state, setState] = useState('');
  const { setStepValidation, setNextLabel } = useStepper();

  useEffect(() => {
    setNextLabel("Continue to System Selection");

    let isValid = false;
    
    if (hasSolar === false) {
      isValid = true;
      onComplete({
        hasSolar: false,
        state: 'NONE'
      });
    }
    else if (hasSolar === true) {
      const sizeInKw = parseFloat(systemSize);
      isValid = state !== '' && !isNaN(sizeInKw) && sizeInKw > 0;
      
      if (isValid) {
        const estimatedProduction = calculateSolarProduction(sizeInKw, state);
        onComplete({
          hasSolar: true,
          systemSize: sizeInKw,
          state,
          estimatedDailyProduction: estimatedProduction
        });
      }
    }

    setStepValidation({ 
      isValid,
      errors: isValid ? [] : ['Please complete all required fields']
    });
  }, [hasSolar, systemSize, state, setStepValidation, setNextLabel, onComplete]);

  return (
    <div className="space-y-6 mb-24">
      <div className="bg-white rounded-xl shadow-lg p-8 border border-gray-100">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-bold text-gray-900">Solar System Evaluation</h2>
          <button
            onClick={onBack}
            className="px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Back
          </button>
        </div>

        <div className="space-y-8">
          <div>
            <label className="text-lg font-medium text-gray-900 block mb-4">
              Do you have solar panels installed?
            </label>
            <div className="grid grid-cols-2 gap-6">
              <button
                onClick={() => setHasSolar(true)}
                className={`p-6 rounded-xl border-2 transition-all ${
                  hasSolar === true 
                    ? 'border-blue-500 bg-blue-50 ring-2 ring-blue-200' 
                    : 'border-gray-200 hover:border-blue-200'
                }`}
              >
                <p className="text-lg font-medium text-gray-900">Yes</p>
                <p className="text-sm text-gray-600 mt-2">I have solar panels installed</p>
              </button>
              <button
                onClick={() => {
                  setHasSolar(false);
                  setSystemSize('');
                  setState('');
                }}
                className={`p-6 rounded-xl border-2 transition-all ${
                  hasSolar === false 
                    ? 'border-blue-500 bg-blue-50 ring-2 ring-blue-200' 
                    : 'border-gray-200 hover:border-blue-200'
                }`}
              >
                <p className="text-lg font-medium text-gray-900">No</p>
                <p className="text-sm text-gray-600 mt-2">I don't have solar panels</p>
              </button>
            </div>
          </div>

          {hasSolar === true && (
            <div className="space-y-6">
              <div>
                <label htmlFor="state" className="block text-lg font-medium text-gray-900 mb-2">
                  State <span className="text-red-500">*</span>
                </label>
                <select
                  id="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg p-4"
                >
                  <option value="">Select your state</option>
                  {states.map(state => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="systemSize" className="block text-lg font-medium text-gray-900 mb-2">
                  Solar System Size <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <input
                    type="number"
                    id="systemSize"
                    value={systemSize}
                    onChange={(e) => setSystemSize(e.target.value)}
                    min="0"
                    step="0.1"
                    className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg p-4 pr-16"
                    placeholder="Enter system size"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <span className="text-gray-500">kW</span>
                  </div>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Enter the total size of your solar system in kilowatts (kW)
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};