import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuoteDetails, CustomerInfo } from '../types';
import { CustomerForm } from './schedule/CustomerForm';
import { DateTimePicker } from './schedule/DateTimePicker';
import { ProposalPdfButton } from './ProposalPdfButton';
import { validateContactInfo } from '../utils/validation/contactValidation';
import { motion } from 'framer-motion';
import { useStepper } from '../hooks/useStepper';
import { createQuote } from '../lib/supabase/queries/quotes';
import { ContactValidation } from '../types/validation';

interface ScheduleSiteVisitProps {
  quoteDetails: QuoteDetails;
  onBack: () => void;
}

export const ScheduleSiteVisit: React.FC<ScheduleSiteVisitProps> = ({
  quoteDetails,
  onBack
}) => {
  const navigate = useNavigate();
  const { setStepValidation, handleSubmit } = useStepper();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: ''
  });
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errors, setErrors] = useState<ContactValidation>({});
  const [isSaving, setIsSaving] = useState(false);

  const validateForm = () => {
    console.log('Validating form...', { customerInfo, selectedDate, selectedTime });
    
    const contactValidation = validateContactInfo(
      customerInfo.firstName,
      customerInfo.lastName,
      customerInfo.email,
      customerInfo.phone
    );

    const newErrors: ContactValidation = { ...contactValidation.errors };

    if (!customerInfo.address) {
      newErrors.address = 'Address is required';
    }

    if (!selectedDate || !selectedTime) {
      newErrors.appointment = 'Please select both a date and time for your visit';
    }

    const isValid = contactValidation.isValid && !newErrors.appointment;
    console.log('Form validation result:', { isValid, errors: newErrors });

    return {
      isValid,
      errors: newErrors
    };
  };

  useEffect(() => {
    const validation = validateForm();
    setErrors(validation.errors);
    setStepValidation({ 
      isValid: validation.isValid,
      errors: Object.values(validation.errors).filter(Boolean) as string[]
    });
  }, [customerInfo, selectedDate, selectedTime, setStepValidation]);

  const handleCustomerInfoChange = (field: string, value: string) => {
    setCustomerInfo(prev => ({ ...prev, [field]: value }));
  };

  const handleValidation = (field: keyof ContactValidation, error: string | undefined) => {
    setErrors(prev => ({
      ...prev,
      [field]: error
    }));
  };

  const handleDateSelect = (date: Date | undefined) => {
    setSelectedDate(date);
  };

  const handleTimeSelect = (time: string) => {
    setSelectedTime(time);
  };

  const handleSaveForLater = async () => {
    try {
      setIsSaving(true);
      await createQuote(customerInfo, quoteDetails);
      navigate('/proposals');
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        submit: error instanceof Error ? error.message : 'Failed to save quote'
      }));
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    // Set up the submit handler
    handleSubmit.current = async () => {
      console.log('Handling submit...', { isSubmitting, submitSuccess });
      
      const validation = validateForm();
      if (!validation.isValid) {
        console.log('Form validation failed', validation.errors);
        setErrors(validation.errors);
        return;
      }

      if (!selectedDate || !selectedTime) {
        throw new Error('Please select appointment date and time');
      }

      setIsSubmitting(true);
      setErrors({});

      try {
        console.log('Creating quote...', {
          customerInfo,
          quoteDetails,
          selectedDate,
          selectedTime
        });

        await createQuote(
          customerInfo,
          quoteDetails,
          selectedDate,
          selectedTime
        );
        
        console.log('Quote created successfully');
        setSubmitSuccess(true);
        navigate('/proposals');
      } catch (error) {
        console.error('Submit error:', error);
        setErrors(prev => ({
          ...prev,
          submit: error instanceof Error ? error.message : 'An unexpected error occurred'
        }));
        throw error; // Re-throw to trigger error handling in stepper
      } finally {
        setIsSubmitting(false);
      }
    };
  }, [customerInfo, selectedDate, selectedTime, quoteDetails, handleSubmit, navigate]);

  if (submitSuccess) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-8 mb-24"
      >
        <div className="bg-green-50 rounded-xl p-8 border border-green-100">
          <h2 className="text-2xl font-bold text-green-900 mb-4">
            Site Visit Scheduled Successfully!
          </h2>
          <p className="text-green-700 mb-6">
            Thank you for scheduling your site visit. We'll be in touch soon to confirm your appointment.
          </p>
          <ProposalPdfButton 
            quoteDetails={quoteDetails}
            customerInfo={customerInfo}
          />
        </div>
      </motion.div>
    );
  }

  return (
    <div className="space-y-8 mb-24">
      <div className="bg-white rounded-xl shadow-lg p-8">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-4">
            <button
              onClick={handleSaveForLater}
              disabled={isSaving}
              className="px-6 py-3 bg-white text-blue-600 font-medium rounded-lg border border-blue-200 hover:bg-blue-50 transition-colors disabled:opacity-50"
            >
              {isSaving ? 'Saving...' : 'Save for Later'}
            </button>
            <ProposalPdfButton quoteDetails={quoteDetails} />
          </div>
          <button
            onClick={onBack}
            className="px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Back
          </button>
        </div>

        <h2 className="text-2xl font-bold text-gray-900 mb-8">Schedule Your Site Visit</h2>
        
        <div className="space-y-8">
          <CustomerForm
            customerInfo={customerInfo}
            onChange={handleCustomerInfoChange}
            onValidate={handleValidation}
            errors={errors}
            className="mb-8"
          />

          <DateTimePicker
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            onDateSelect={handleDateSelect}
            onTimeSelect={handleTimeSelect}
          />

          {errors.appointment && (
            <div className="p-4 bg-red-50 text-red-700 rounded-lg border border-red-100">
              {errors.appointment}
            </div>
          )}

          {errors.submit && (
            <div className="p-4 bg-red-50 text-red-700 rounded-lg border border-red-100">
              {errors.submit}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};