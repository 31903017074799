import React from 'react';
import { SystemTier, CalculationResult, SolarDetails } from '../types';
import { BoltIcon } from '@heroicons/react/24/outline';
import { batterySpecs } from '../data/batterySpecs';
import { BatteryManager } from './BatteryManager';
import { InverterDisplay } from './InverterDisplay';
import { SystemCapacityInfo } from './SystemCapacityInfo';
import { SelectedAppliancesSummary } from './SelectedAppliancesSummary';

interface SystemRecommendationProps {
  result: CalculationResult;
  solarDetails: SolarDetails | null;
  onSystemSelect: (system: SystemTier, extraBatteries: number, totalPrice: number, backupHours: number) => void;
  onUpdateAppliances: (index: number, quantity: number) => void;
  showPreRebatePrice: boolean;
  onBack: () => void;
}

export const SystemRecommendation: React.FC<SystemRecommendationProps> = ({
  result,
  solarDetails,
  onSystemSelect,
  onUpdateAppliances,
  showPreRebatePrice,
  onBack
}) => {
  const systemTiers = result.systemKit?.systemTiers || [];
  const requiredInverters = result.systemKit?.inverters || 0;

  const calculateTotalPrice = (system: SystemTier) => {
    const baseCost = system.price || 0;
    const installationCost = 
      (batterySpecs.basePrice.installation.inverter * system.inverters) +
      (batterySpecs.basePrice.installation.battery * system.batteries);
    const totalCost = baseCost + installationCost;
    return showPreRebatePrice ? totalCost : Math.round(totalCost * 0.7);
  };

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">Power Analysis</h2>
        <button
          onClick={onBack}
          className="px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
        >
          Back
        </button>
      </div>

      <div className="bg-white rounded-xl shadow-lg p-6">
        <SelectedAppliancesSummary 
          appliances={result.systemKit.appliances}
          solarDetails={solarDetails}
        />
      </div>

      <div className="grid grid-cols-3 gap-6">
        {systemTiers.map((system, index) => {
          const totalPrice = calculateTotalPrice(system);
          const backupHours = result.systemKit?.backupHours || 0;
          const isRecommended = index === 1;

          return (
            <div 
              key={system.name} 
              className={`bg-white rounded-xl shadow-lg p-6 transition-all ${
                isRecommended ? 'ring-2 ring-blue-500 transform scale-105' : 'hover:ring-2 hover:ring-blue-200'
              }`}
            >
              <div className="flex flex-col h-full">
                <div className="mb-6">
                  <div className="flex items-start justify-between">
                    <div>
                      <h3 className="text-xl font-bold text-gray-900">
                        {system.name}
                      </h3>
                      {isRecommended && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mt-2">
                          Recommended
                        </span>
                      )}
                      <p className="text-gray-600 mt-2">{system.description}</p>
                    </div>
                  </div>
                </div>

                <div className="flex-grow space-y-6">
                  <InverterDisplay inverterCount={system.inverters} />
                  <BatteryManager
                    baseBatteries={system.batteries}
                    extraBatteries={0}
                    inverterCount={system.inverters}
                  />

                  <SystemCapacityInfo
                    inverterCount={system.inverters}
                    batteryCount={system.batteries}
                    dailyUsage={result.dailyUsage}
                    totalAmps={result.totalAmps}
                  />

                  {system.coverage && (
                    <div className="space-y-2">
                      <h4 className="font-medium text-gray-900">Coverage:</h4>
                      <ul className="space-y-2">
                        {system.coverage.map((item, i) => (
                          <li key={i} className="flex items-start space-x-2 text-sm text-gray-600">
                            <BoltIcon className="h-5 w-5 text-blue-500 flex-shrink-0" />
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="pt-6 mt-6 border-t border-gray-200">
                  <div className="text-center">
                    <p className="text-sm text-gray-500 mb-2">Total Investment</p>
                    <p className="text-3xl font-bold text-gray-900 mb-2">
                      ${totalPrice.toLocaleString()}
                    </p>
                    <p className="text-sm text-gray-500 mb-6">
                      {showPreRebatePrice ? 'Before federal tax credit' : 'After 30% federal tax credit'}
                    </p>
                    <button
                      onClick={() => onSystemSelect(system, 0, totalPrice, backupHours)}
                      className={`w-full py-4 px-6 rounded-lg transition-colors ${
                        isRecommended
                          ? 'bg-blue-600 text-white hover:bg-blue-700'
                          : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                      }`}
                    >
                      Select {system.name} Package
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};