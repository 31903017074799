import { useContext } from 'react';
import { StepperContext } from '../contexts/StepperContext';
import type { StepperContextType } from '../types/stepper';

export const useStepper = (): StepperContextType => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error('useStepper must be used within a StepperProvider');
  }
  return context;
};