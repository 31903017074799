import { ApplianceItem } from '../../../types';
import { defaultAppliances } from '../../../data/appliances';

export async function fetchAppliances(): Promise<ApplianceItem[]> {
  try {
    // Return the default appliances since we're not using a database table
    return defaultAppliances;
  } catch (error) {
    console.error('Error fetching appliances:', error);
    throw error instanceof Error 
      ? error 
      : new Error('An unexpected error occurred while fetching appliances');
  }
}