import { batterySpecs } from '../data/batterySpecs';

export function calculateBackupHours(dailyUsage: number, batteryCount: number, solarOffset: number = 0): number {
  if (dailyUsage <= 0) return 0;
  
  // Calculate net daily usage after solar production
  const netDailyUsage = Math.max(0.1, dailyUsage - solarOffset); // Prevent division by zero, minimum 0.1 kWh
  
  // Calculate total usable capacity considering:
  // 1. Total capacity per battery
  // 2. Number of batteries
  // 3. Usable capacity percentage (80%)
  // 4. Round-trip efficiency
  const totalUsableCapacity = 
    batteryCount * 
    batterySpecs.energyCapacity * 
    batterySpecs.usableCapacity * 
    batterySpecs.inverter.roundTripEfficiency;
  
  // Calculate backup hours based on net usage
  const backupHours = (totalUsableCapacity / netDailyUsage) * 24;
  
  // Cap maximum backup hours at 168 (1 week)
  return Math.min(168, Math.round(backupHours));
}