import React from 'react';
import { format } from 'date-fns';
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/outline';

interface AppointmentCardProps {
  appointmentDate: string | null;
  appointmentTime: string | null;
  status: string;
}

export const AppointmentCard: React.FC<AppointmentCardProps> = ({
  appointmentDate,
  appointmentTime,
  status
}) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'approved':
        return 'bg-green-100 text-green-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">Appointment Details</h2>
      
      <div className="space-y-4">
        <div className={`inline-flex px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(status)}`}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </div>

        {appointmentDate && (
          <div className="flex items-start space-x-3">
            <CalendarIcon className="w-5 h-5 text-gray-400 mt-0.5" />
            <div>
              <p className="font-medium text-gray-900">
                {format(new Date(appointmentDate), 'MMMM d, yyyy')}
              </p>
              <p className="text-sm text-gray-500">Scheduled Date</p>
            </div>
          </div>
        )}

        {appointmentTime && (
          <div className="flex items-start space-x-3">
            <ClockIcon className="w-5 h-5 text-gray-400 mt-0.5" />
            <div>
              <p className="font-medium text-gray-900">{appointmentTime}</p>
              <p className="text-sm text-gray-500">Scheduled Time</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};