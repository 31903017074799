import React, { createContext, useContext } from 'react';
import { User } from '@supabase/supabase-js';
import { useSession } from '../lib/auth/hooks/useSession';
import { AuthService } from '../lib/auth/AuthService';

export interface AuthContextType {
  user: User | null;
  loading: boolean;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  signOut: async () => {}
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { session, loading } = useSession();

  const value: AuthContextType = {
    user: session?.user ?? null,
    loading,
    signOut: AuthService.signOut
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};