import React from 'react';
import { CustomerInfo } from '../../../types';

interface CustomerInfoCardProps {
  customerInfo: CustomerInfo;
}

export const CustomerInfoCard: React.FC<CustomerInfoCardProps> = ({ customerInfo }) => {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">Customer Information</h2>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="text-sm text-gray-500">Name</p>
          <p className="font-medium text-gray-900">
            {customerInfo.firstName} {customerInfo.lastName}
          </p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Email</p>
          <p className="font-medium text-gray-900">{customerInfo.email}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Phone</p>
          <p className="font-medium text-gray-900">{customerInfo.phone}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Address</p>
          <p className="font-medium text-gray-900">{customerInfo.address}</p>
        </div>
      </div>
    </div>
  );
};