import { PostgrestError } from '@supabase/supabase-js';

export class DatabaseError extends Error {
  constructor(
    message: string,
    public originalError?: PostgrestError | Error
  ) {
    super(message);
    this.name = 'DatabaseError';
  }
}

export function handleSupabaseError(error: PostgrestError | Error): never {
  console.error('Supabase error:', error);
  
  const message = error instanceof Error 
    ? error.message 
    : 'An unexpected database error occurred';
  
  throw new DatabaseError(message, error);
}