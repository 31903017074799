import React from 'react';
import { StepWrapper } from '../stepper/StepWrapper';
import { QuoteDetails } from '../../types';
import { batterySpecs } from '../../data/batterySpecs';

interface QuoteStepProps {
  quoteDetails: QuoteDetails;
  showPreRebatePrice: boolean;
  onBack: () => void;
  onPriceToggle: (show: boolean) => void;
}

export const QuoteStep: React.FC<QuoteStepProps> = ({
  quoteDetails,
  showPreRebatePrice
}) => {
  // Calculate total installation cost
  const totalInstallationCost = 
    (batterySpecs.basePrice.installation.inverter * quoteDetails.selectedSystem.inverters) +
    (batterySpecs.basePrice.installation.battery * (quoteDetails.selectedSystem.batteries + quoteDetails.extraBatteries));

  // Calculate equipment costs
  const inverterCost = quoteDetails.selectedSystem.inverters * batterySpecs.basePrice.inverter;
  const batteryCost = quoteDetails.selectedSystem.batteries * batterySpecs.basePrice.battery;
  const equipmentCost = inverterCost + batteryCost;

  // Calculate totals
  const subtotal = equipmentCost + totalInstallationCost;
  const taxRate = 0.08; // Example tax rate
  const taxAmount = equipmentCost * taxRate; // Only equipment is taxable
  const totalBeforeRebate = subtotal + taxAmount;
  const federalRebate = totalBeforeRebate * 0.3;
  const finalTotal = totalBeforeRebate - federalRebate;

  return (
    <StepWrapper
      validation={{ isValid: true, errors: [] }}
      nextLabel="Schedule Site Visit"
    >
      <div className="space-y-6 mb-24">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="space-y-6">
            <h2 className="text-xl font-bold text-gray-900">Quote Summary</h2>
            
            {/* Line Items */}
            <div className="border rounded-lg">
              <div className="px-4 py-3 bg-gray-50 border-b">
                <h3 className="font-medium text-gray-900">Line Items</h3>
              </div>
              <div className="divide-y">
                {/* Equipment */}
                <div className="p-4">
                  <div className="flex justify-between mb-2">
                    <p className="text-gray-900">
                      Gen2 Inverters ({quoteDetails.selectedSystem.inverters}x)
                    </p>
                    <p className="text-gray-900">${inverterCost.toLocaleString()}</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-gray-900">
                      Battery Packs ({quoteDetails.selectedSystem.batteries}x)
                    </p>
                    <p className="text-gray-900">${batteryCost.toLocaleString()}</p>
                  </div>
                </div>

                {/* Installation */}
                <div className="p-4">
                  <div className="flex justify-between">
                    <p className="text-gray-900">Professional Installation</p>
                    <p className="text-gray-900">${totalInstallationCost.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Totals */}
            <div className="space-y-3 border-t border-gray-200 pt-4">
              <div className="flex justify-between text-gray-600">
                <span>Equipment Subtotal</span>
                <span>${equipmentCost.toLocaleString()}</span>
              </div>
              <div className="flex justify-between text-gray-600">
                <span>Installation</span>
                <span>${totalInstallationCost.toLocaleString()}</span>
              </div>
              <div className="flex justify-between text-gray-600">
                <span>Sales Tax (8%)</span>
                <span>${taxAmount.toLocaleString()}</span>
              </div>
              <div className="flex justify-between text-gray-900 font-medium">
                <span>Total Before Rebate</span>
                <span>${totalBeforeRebate.toLocaleString()}</span>
              </div>
              <div className="flex justify-between text-green-600">
                <span>Federal Tax Credit (30%)</span>
                <span>-${federalRebate.toLocaleString()}</span>
              </div>
              <div className="flex justify-between text-xl font-bold text-gray-900 pt-3 border-t">
                <span>Final Investment</span>
                <span>${finalTotal.toLocaleString()}</span>
              </div>

              {/* Financing Option */}
              <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                <div className="flex justify-between items-end">
                  <div>
                    <p className="text-sm text-blue-700 font-medium">Monthly Payment Option</p>
                    <p className="text-xs text-blue-600">60 months @ 0% APR</p>
                  </div>
                  <div className="text-right">
                    <p className="text-2xl font-bold text-blue-700">
                      ${Math.round(finalTotal / 60).toLocaleString()}/mo
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StepWrapper>
  );
};