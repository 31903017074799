import React from 'react';
import { Document, Page, View } from '@react-pdf/renderer';
import { QuoteDetails, CustomerInfo } from '../../types';
import { CoverPage } from './CoverPage';
import { SystemRequirements } from './SystemRequirements';
import { RecommendedSolution } from './RecommendedSolution';
import { InvestmentSummary } from './InvestmentSummary';
import { BatterySpecs } from './BatterySpecs';
import { TermsAndConditions } from './TermsAndConditions';
import { pdfStyles } from './styles';

interface ProposalDocumentProps {
  quoteDetails: QuoteDetails;
  customerInfo?: CustomerInfo;
}

export const ProposalDocument: React.FC<ProposalDocumentProps> = ({ quoteDetails, customerInfo }) => {
  return (
    <Document>
      <CoverPage customerInfo={customerInfo} />
      
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.content}>
          <SystemRequirements quoteDetails={quoteDetails} />
        </View>
      </Page>

      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.content}>
          <RecommendedSolution quoteDetails={quoteDetails} />
          <BatterySpecs />
        </View>
      </Page>

      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.content}>
          <InvestmentSummary quoteDetails={quoteDetails} />
          <TermsAndConditions />
        </View>
      </Page>
    </Document>
  );
};