import React, { useState, useEffect } from 'react';
import { SystemRecommendation } from '../SystemRecommendation';
import { StepWrapper } from '../stepper/StepWrapper';
import { CalculationResult, SystemTier, SolarDetails } from '../../types';

interface SystemStepProps {
  result: CalculationResult;
  solarDetails: SolarDetails | null;
  onSystemSelect: (system: SystemTier, extraBatteries: number, totalPrice: number, backupHours: number) => void;
  onUpdateAppliances: (index: number, quantity: number) => void;
  showPreRebatePrice: boolean;
  onBack: () => void;
}

export const SystemStep: React.FC<SystemStepProps> = ({
  result,
  solarDetails,
  onSystemSelect,
  onUpdateAppliances,
  showPreRebatePrice,
  onBack
}) => {
  const [selectedSystem, setSelectedSystem] = useState<SystemTier | null>(null);

  const handleSystemSelect = (
    system: SystemTier,
    extraBatteries: number,
    totalPrice: number,
    backupHours: number
  ) => {
    setSelectedSystem(system);
    onSystemSelect(system, extraBatteries, totalPrice, backupHours);
  };

  return (
    <StepWrapper
      validation={{ 
        isValid: selectedSystem !== null,
        errors: selectedSystem === null ? ['Please select a system package to continue'] : []
      }}
      nextLabel="Continue to Quote"
    >
      <SystemRecommendation
        result={result}
        onSystemSelect={handleSystemSelect}
        solarDetails={solarDetails}
        onUpdateAppliances={onUpdateAppliances}
        showPreRebatePrice={showPreRebatePrice}
        onBack={onBack}
      />
    </StepWrapper>
  );
};