import React, { useState, useEffect, useRef, useCallback } from 'react';
import { format } from 'date-fns';
import { PlusIcon, EyeIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { CreateProposalModal } from './CreateProposalModal';
import { ProposalSearch } from './ProposalSearch';
import { ProposalFilters, ProposalFilters as FilterType } from './ProposalFilters';
import { fetchProposals } from '../../lib/supabase/queries/proposals';
import { Proposal } from '../../lib/supabase/queries/proposals/types';

const PAGE_SIZE = 50;

export const ProposalList: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<FilterType>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const observer = useRef<IntersectionObserver>();
  const loadingRef = useRef<HTMLDivElement>(null);

  const loadProposals = useCallback(async (pageNum: number, search: string, currentFilters: FilterType) => {
    try {
      setIsLoading(true);
      const { data, count } = await fetchProposals(pageNum, PAGE_SIZE, search, currentFilters);
      
      if (pageNum === 0) {
        setProposals(data);
      } else {
        setProposals(prev => [...prev, ...data]);
      }
      
      setTotalCount(count);
      setHasMore(data.length === PAGE_SIZE && (pageNum + 1) * PAGE_SIZE < count);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load proposals');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setPage(0);
    setProposals([]);
    loadProposals(0, searchTerm, filters);
  }, [searchTerm, filters, loadProposals]);

  useEffect(() => {
    if (isLoading || !hasMore) return;

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prev => prev + 1);
        loadProposals(page + 1, searchTerm, filters);
      }
    });

    if (loadingRef.current) {
      observer.current.observe(loadingRef.current);
    }
  }, [isLoading, hasMore, page, searchTerm, filters, loadProposals]);

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Proposals</h1>
          <p className="text-sm text-gray-500 mt-1">
            Showing {proposals.length} of {totalCount} proposals
          </p>
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <PlusIcon className="w-5 h-5 mr-2" />
          Create Proposal
        </button>
      </div>

      <div className="space-y-4 mb-6">
        <ProposalSearch 
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
        />
        
        <ProposalFilters
          filters={filters}
          onFiltersChange={setFilters}
        />
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="grid grid-cols-7 gap-4 p-4 border-b border-gray-200 font-medium text-gray-700">
          <div>Proposal #</div>
          <div>Date</div>
          <div className="col-span-2">Customer</div>
          <div>Status</div>
          <div>Amount</div>
          <div>Actions</div>
        </div>

        {proposals.length === 0 && !isLoading ? (
          <div className="p-8 text-center">
            <p className="text-gray-500">
              {searchTerm || Object.keys(filters).length > 0 
                ? 'No proposals match your search criteria' 
                : 'No proposals yet'}
            </p>
          </div>
        ) : (
          <div className="divide-y divide-gray-200">
            {proposals.map((proposal) => (
              <div key={proposal.id} className="grid grid-cols-7 gap-4 p-4 text-sm">
                <div className="text-gray-900">
                  {proposal.id}
                </div>
                <div className="text-gray-900">
                  {format(new Date(proposal.created_at), 'MMM d, yyyy')}
                </div>
                <div className="col-span-2">
                  <p className="text-gray-900">
                    {proposal.customer_info.firstName} {proposal.customer_info.lastName}
                  </p>
                  <p className="text-gray-500 text-xs mt-1">
                    {proposal.customer_info.phone}
                  </p>
                  <p className="text-gray-500 text-xs mt-0.5">
                    {proposal.customer_info.address}
                  </p>
                </div>
                <div>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    proposal.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                    proposal.status === 'approved' ? 'bg-green-100 text-green-800' :
                    proposal.status === 'rejected' ? 'bg-red-100 text-red-800' :
                    'bg-gray-100 text-gray-800'
                  }`}>
                    {proposal.status.charAt(0).toUpperCase() + proposal.status.slice(1)}
                  </span>
                </div>
                <div className="text-gray-900">
                  ${proposal.quote_details.totalPrice.toLocaleString()}
                </div>
                <div>
                  <Link 
                    to={`/proposals/${proposal.id}`}
                    className="text-blue-600 hover:text-blue-700 transition-colors"
                  >
                    <EyeIcon className="w-5 h-5" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}

        <div ref={loadingRef} className="p-4 text-center">
          {isLoading && (
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          )}
        </div>
      </div>

      <CreateProposalModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};