import { supabase } from '../../config';
import { handleSupabaseError } from '../../utils/error';
import { Proposal, DbQuote } from './types';
import { transformCustomerInfo, transformQuoteDetails } from './transformers';

export async function fetchProposalById(id: string): Promise<Proposal> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      throw new Error('User not authenticated');
    }

    const { data, error } = await supabase
      .from('quotes')
      .select('*')
      .eq('id', id)
      .eq('user_id', user.id)
      .single();

    if (error) {
      handleSupabaseError(error);
    }

    if (!data) {
      throw new Error('Proposal not found');
    }

    const quote = data as DbQuote;
    return {
      id: quote.id,
      created_at: quote.created_at,
      customer_info: transformCustomerInfo(quote.customer_info),
      quote_details: transformQuoteDetails(quote.quote_details),
      appointment_date: quote.appointment_date,
      appointment_time: quote.appointment_time,
      status: quote.status,
      user_id: quote.user_id
    };
  } catch (error) {
    console.error('Error in fetchProposalById:', error);
    throw error instanceof Error 
      ? error 
      : new Error('An unexpected error occurred while fetching the proposal');
  }
}