import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { QuoteDetails } from '../../types';
import { batterySpecs } from '../../data/batterySpecs';
import { pdfStyles } from './styles';

interface RecommendedSolutionProps {
  quoteDetails: QuoteDetails;
}

export const RecommendedSolution: React.FC<RecommendedSolutionProps> = ({ quoteDetails }) => {
  const system = quoteDetails.selectedSystem;
  const coverage = system.coverage || [];
  const idealFor = system.idealFor || [];

  return (
    <View style={pdfStyles.section}>
      <Text style={pdfStyles.sectionTitle}>Recommended Solution</Text>
      
      <View style={pdfStyles.highlightCard}>
        <Text style={pdfStyles.subsectionTitle}>{system.name} Package</Text>
        
        <View style={pdfStyles.statsGrid}>
          <View style={pdfStyles.statCard}>
            <Text style={pdfStyles.statTitle}>System Capacity</Text>
            <Text style={pdfStyles.statValue}>
              {(system.batteries * batterySpecs.energyCapacity).toFixed(1)} kWh
            </Text>
            <Text style={pdfStyles.statSubtext}>Total energy storage</Text>
          </View>

          <View style={pdfStyles.statCard}>
            <Text style={pdfStyles.statTitle}>Power Output</Text>
            <Text style={pdfStyles.statValue}>
              {(system.batteries * batterySpecs.power.continuous).toFixed(1)} kW
            </Text>
            <Text style={pdfStyles.statSubtext}>Continuous power</Text>
          </View>

          <View style={pdfStyles.statCard}>
            <Text style={pdfStyles.statTitle}>Backup Duration</Text>
            <Text style={pdfStyles.statValue}>{quoteDetails.backupHours || 0} hrs</Text>
            <Text style={pdfStyles.statSubtext}>At current usage</Text>
          </View>
        </View>

        <View style={{ marginTop: 20 }}>
          <Text style={pdfStyles.subsectionTitle}>System Features</Text>
          {coverage.map((feature, index) => (
            <View key={index} style={pdfStyles.systemFeature}>
              <View style={pdfStyles.featureIcon} />
              <Text style={pdfStyles.featureText}>{feature}</Text>
            </View>
          ))}
        </View>

        <View style={{ marginTop: 20 }}>
          <Text style={pdfStyles.subsectionTitle}>Ideal Applications</Text>
          {idealFor.map((item, index) => (
            <View key={index} style={pdfStyles.systemFeature}>
              <View style={[pdfStyles.featureIcon, { backgroundColor: '#10b981' }]} />
              <Text style={pdfStyles.featureText}>{item}</Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};