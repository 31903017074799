import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useStepper } from '../../hooks/useStepper';
import { useScrollTop } from '../../hooks/useScrollTop';

interface StepContentProps {
  children: React.ReactNode;
}

export const StepContent: React.FC<StepContentProps> = ({ children }) => {
  const { currentStep } = useStepper();
  useScrollTop([currentStep]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={currentStep}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
        className="pb-32"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};