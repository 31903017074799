import React from 'react';
import { SystemTier } from '../../../types';
import { batterySpecs } from '../../../data/batterySpecs';

interface ProposalOptionsCardProps {
  systemTiers: SystemTier[];
  selectedTier: SystemTier;
}

export const ProposalOptionsCard: React.FC<ProposalOptionsCardProps> = ({ 
  systemTiers,
  selectedTier 
}) => {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">System Options</h2>
      <div className="space-y-6">
        {systemTiers.map((tier, index) => (
          <div 
            key={index} 
            className={`border rounded-lg p-4 ${
              tier.name === selectedTier.name ? 'border-blue-500 bg-blue-50' : 'border-gray-200'
            }`}
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="font-semibold text-gray-900">{tier.name} Package</h3>
                <p className="text-sm text-gray-500">{tier.description}</p>
                {tier.name === selectedTier.name && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mt-2">
                    Selected Option
                  </span>
                )}
              </div>
              <div className="text-right">
                <p className="text-lg font-bold text-gray-900">
                  ${(tier.price || 0).toLocaleString()}
                </p>
                <p className="text-sm text-gray-500">After tax credit</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-2">System Details</h4>
                <ul className="text-sm text-gray-600 space-y-1">
                  <li>• {tier.inverters} Gen2 Inverter{tier.inverters > 1 ? 's' : ''}</li>
                  <li>• {tier.batteries} Battery Pack{tier.batteries > 1 ? 's' : ''}</li>
                  <li>• {tier.batteries * batterySpecs.energyCapacity}kWh Total Capacity</li>
                  <li>• {tier.backupHours || 0} Hours Backup Time</li>
                  <li>• {tier.amps || 0}A Maximum Current</li>
                </ul>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-2">Ideal Applications</h4>
                <ul className="text-sm text-gray-600 space-y-1">
                  {tier.idealFor?.map((item, i) => (
                    <li key={i}>• {item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};