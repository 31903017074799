import React from 'react';
import { ScheduleSiteVisit } from '../ScheduleSiteVisit';
import { StepWrapper } from '../stepper/StepWrapper';
import { QuoteDetails } from '../../types';

interface ScheduleStepProps {
  quoteDetails: QuoteDetails;
  onBack: () => void;
}

export const ScheduleStep: React.FC<ScheduleStepProps> = ({ quoteDetails, onBack }) => {
  return (
    <StepWrapper
      validation={{ isValid: true, errors: [] }}
      nextLabel="Submit"
    >
      <ScheduleSiteVisit 
        quoteDetails={quoteDetails}
        onBack={onBack}
      />
    </StepWrapper>
  );
};