import { Session, User } from '@supabase/supabase-js';
import { supabase } from '../supabase/config';

export class AuthService {
  static async getSession(): Promise<Session | null> {
    const { data: { session } } = await supabase.auth.getSession();
    return session;
  }

  static onAuthStateChange(callback: (session: Session | null) => void) {
    return supabase.auth.onAuthStateChange((_event, session) => {
      callback(session);
    });
  }

  static async signOut(): Promise<void> {
    await supabase.auth.signOut();
  }

  static async getCurrentUser(): Promise<User | null> {
    const { data: { user } } = await supabase.auth.getUser();
    return user;
  }

  static async handleInvite(token: string, email: string): Promise<User | null> {
    try {
      const { data: { user }, error } = await supabase.auth.verifyOtp({
        token,
        type: 'invite',
        email
      });

      if (error) throw error;
      return user;
    } catch (error) {
      console.error('Error handling invite:', error);
      throw error;
    }
  }
}