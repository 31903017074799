import { supabase } from '../config';
import { handleSupabaseError } from '../utils/error';
import { CustomerInfo, QuoteDetails } from '../../../types';
import { Database } from '../database.types';

type QuoteInsert = Database['public']['Tables']['quotes']['Insert'];
type JsonValue = Database['public']['Tables']['quotes']['Row']['customer_info'];

const generateProposalNumber = () => {
  return `AG-${Math.random().toString(36).substring(2, 10).toUpperCase()}`;
};

export async function createQuote(
  customerInfo: CustomerInfo,
  quoteDetails: QuoteDetails,
  appointmentDate?: Date,
  appointmentTime?: string
) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      throw new Error('User not authenticated');
    }

    const quoteData: QuoteInsert = {
      proposal_number: generateProposalNumber(),
      customer_info: JSON.parse(JSON.stringify(customerInfo)) as JsonValue,
      quote_details: JSON.parse(JSON.stringify(quoteDetails)) as JsonValue,
      appointment_date: appointmentDate?.toISOString(),
      appointment_time: appointmentTime,
      status: appointmentDate ? 'pending' : 'draft',
      user_id: user.id
    };

    const { data, error } = await supabase
      .from('quotes')
      .insert(quoteData)
      .select()
      .single();

    if (error) {
      handleSupabaseError(error);
    }

    if (!data) {
      throw new Error('Failed to create quote');
    }

    return data;
  } catch (error) {
    console.error('Error in createQuote:', error);
    throw error instanceof Error 
      ? error 
      : new Error('An unexpected error occurred while creating the quote');
  }
}