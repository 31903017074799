import React, { useMemo } from 'react';
import { ApplianceList } from '../ApplianceList';
import { StepWrapper } from '../stepper/StepWrapper';
import { ApplianceItem } from '../../types';

interface ApplianceStepProps {
  appliances: ApplianceItem[];
  onUpdateQuantity: (index: number, quantity: number) => void;
}

export const ApplianceStep: React.FC<ApplianceStepProps> = ({
  appliances,
  onUpdateQuantity
}) => {
  const validation = useMemo(() => {
    const hasSelectedAppliances = appliances.some(a => a.quantity > 0);
    return {
      isValid: hasSelectedAppliances,
      errors: hasSelectedAppliances ? [] : ['Please select at least one appliance']
    };
  }, [appliances]);

  return (
    <StepWrapper
      validation={validation}
      nextLabel="Continue to Solar Evaluation"
    >
      <ApplianceList 
        appliances={appliances}
        onUpdateQuantity={onUpdateQuantity}
      />
    </StepWrapper>
  );
};