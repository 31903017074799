import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { pdfStyles } from './styles';

export const BatterySpecs: React.FC = () => (
  <View style={pdfStyles.section}>
    <Text style={pdfStyles.sectionTitle}>Lion Energy Battery System</Text>
    
    <View style={pdfStyles.card}>
      <Text style={pdfStyles.subsectionTitle}>Premium Energy Storage Solution</Text>
      <Text style={pdfStyles.termsParagraph}>
        The Lion Energy battery system represents the pinnacle of home energy storage technology, 
        offering unparalleled performance, reliability, and safety for your home backup power needs.
      </Text>

      <View style={{ marginTop: 20 }}>
        <Text style={pdfStyles.subsectionTitle}>Key Features</Text>
        <View style={pdfStyles.statsGrid}>
          <View style={pdfStyles.statCard}>
            <Text style={pdfStyles.statTitle}>Advanced Technology</Text>
            <Text style={pdfStyles.statValue}>LiFePO4</Text>
            <Text style={pdfStyles.statSubtext}>Battery Chemistry</Text>
          </View>
          <View style={pdfStyles.statCard}>
            <Text style={pdfStyles.statTitle}>Cycle Life</Text>
            <Text style={pdfStyles.statValue}>6,000+</Text>
            <Text style={pdfStyles.statSubtext}>Charge Cycles</Text>
          </View>
          <View style={pdfStyles.statCard}>
            <Text style={pdfStyles.statTitle}>Warranty</Text>
            <Text style={pdfStyles.statValue}>10 Years</Text>
            <Text style={pdfStyles.statSubtext}>Limited Warranty</Text>
          </View>
        </View>
      </View>

      <View style={{ marginTop: 20 }}>
        <Text style={pdfStyles.subsectionTitle}>System Benefits</Text>
        {[
          "Safe & Stable LiFePO4 Chemistry - The safest lithium battery technology available",
          "Long-Lasting Performance - Up to 6,000 cycles at 80% depth of discharge",
          "Maintenance-Free Operation - No regular maintenance required",
          "Indoor/Outdoor Rated - Versatile installation options",
          "Scalable Design - Easy to expand as your needs grow",
          "Smart Monitoring - Real-time system monitoring and control",
          "Made in the USA - Quality manufacturing and support"
        ].map((benefit, index) => (
          <View key={index} style={pdfStyles.systemFeature}>
            <View style={pdfStyles.featureIcon} />
            <Text style={pdfStyles.featureText}>{benefit}</Text>
          </View>
        ))}
      </View>

      <View style={{ marginTop: 20 }}>
        <Text style={pdfStyles.subsectionTitle}>Technical Specifications</Text>
        <View style={pdfStyles.tableRow}>
          <Text style={pdfStyles.col1}>Battery Chemistry</Text>
          <Text style={pdfStyles.col2}>Lithium Iron Phosphate (LiFePO4)</Text>
        </View>
        <View style={pdfStyles.tableRow}>
          <Text style={pdfStyles.col1}>Operating Temperature</Text>
          <Text style={pdfStyles.col2}>32°F to 86°F (0°C to 30°C)</Text>
        </View>
        <View style={pdfStyles.tableRow}>
          <Text style={pdfStyles.col1}>Communication</Text>
          <Text style={pdfStyles.col2}>CAN Bus / Modbus</Text>
        </View>
        <View style={pdfStyles.tableRow}>
          <Text style={pdfStyles.col1}>Safety Certifications</Text>
          <Text style={pdfStyles.col2}>UL 1973, UL 9540A, UN 38.3</Text>
        </View>
      </View>
    </View>
  </View>
);