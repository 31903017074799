import React from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

interface StepperFooterProps {
  currentStep: number;
  totalSteps: number;
  onNext: () => void;
  onBack: () => void;
  isStepValid?: boolean;
  nextLabel?: string;
  showSubmit?: boolean;
  showPriceToggle?: boolean;
  showPreRebatePrice?: boolean;
  onPriceToggle?: (show: boolean) => void;
}

export const StepperFooter: React.FC<StepperFooterProps> = ({
  currentStep,
  totalSteps,
  onNext,
  onBack,
  isStepValid = true,
  nextLabel = "Continue",
  showSubmit = false,
  showPriceToggle,
  showPreRebatePrice,
  onPriceToggle
}) => {
  const isLastStep = currentStep === totalSteps - 1;
  const isFirstStep = currentStep === 0;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex justify-between items-center">
          <div>
            {!isFirstStep && (
              <button
                onClick={onBack}
                className="inline-flex items-center px-4 py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <ArrowLeftIcon className="w-5 h-5 mr-2" />
                Back
              </button>
            )}
          </div>

          <div className="flex items-center space-x-6">
            {showPriceToggle && onPriceToggle && (
              <button
                onClick={() => onPriceToggle(!showPreRebatePrice)}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  showPreRebatePrice 
                    ? 'bg-blue-100 text-blue-700' 
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                {showPreRebatePrice ? 'Show After Rebate' : 'Show Before Rebate'}
              </button>
            )}

            <button
              onClick={onNext}
              disabled={!isStepValid}
              className={`inline-flex items-center px-6 py-3 rounded-lg transition-colors ${
                isStepValid
                  ? isLastStep && showSubmit
                    ? 'bg-green-600 hover:bg-green-700 text-white'
                    : 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
              }`}
            >
              {isLastStep && showSubmit ? 'Submit' : nextLabel}
              {!isLastStep && <ArrowRightIcon className="w-5 h-5 ml-2" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};