import { supabase } from '../config';
import { handleSupabaseError } from '../utils/error';
import { ProposalFilters } from '../../../components/proposals/ProposalFilters';
import { startOfDay, endOfDay } from 'date-fns';
import { Database } from '../database.types';
import { Proposal, PaginatedProposals } from './proposals/types';
import { transformCustomerInfo, transformQuoteDetails } from './proposals/transformers';

type DbQuote = Database['public']['Tables']['quotes']['Row'];

export async function fetchProposals(
  page: number = 0,
  pageSize: number = 50,
  searchTerm: string = '',
  filters: ProposalFilters = {}
): Promise<PaginatedProposals> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      throw new Error('User not authenticated');
    }

    const start = page * pageSize;
    const end = start + pageSize - 1;

    let query = supabase
      .from('quotes')
      .select('*', { count: 'exact' });

    if (searchTerm.trim()) {
      query = query.or(
        `customer_info->>'firstName'.ilike.%${searchTerm}%,` +
        `customer_info->>'lastName'.ilike.%${searchTerm}%,` +
        `customer_info->>'email'.ilike.%${searchTerm}%,` +
        `customer_info->>'phone'.ilike.%${searchTerm}%,` +
        `customer_info->>'address'.ilike.%${searchTerm}%`
      );
    }

    if (filters.startDate) {
      query = query.gte('created_at', startOfDay(filters.startDate).toISOString());
    }
    if (filters.endDate) {
      query = query.lte('created_at', endOfDay(filters.endDate).toISOString());
    }

    if (filters.status) {
      query = query.eq('status', filters.status);
    }

    const { data, error, count } = await query
      .order('created_at', { ascending: false })
      .range(start, end);

    if (error) {
      handleSupabaseError(error);
    }

    return {
      data: transformQuotes(data as DbQuote[] || []),
      count: count || 0
    };
  } catch (error) {
    console.error('Error in fetchProposals:', error);
    throw error instanceof Error 
      ? error 
      : new Error('An unexpected error occurred while fetching proposals');
  }
}

function transformQuotes(quotes: DbQuote[]): Proposal[] {
  return quotes.map(quote => ({
    id: quote.id,
    created_at: quote.created_at,
    customer_info: transformCustomerInfo(quote.customer_info),
    quote_details: transformQuoteDetails(quote.quote_details),
    appointment_date: quote.appointment_date,
    appointment_time: quote.appointment_time,
    status: quote.status,
    user_id: quote.user_id
  }));
}