import React from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { DocumentTextIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../contexts/AuthContext';

export const MainLayout: React.FC = () => {
  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="flex h-screen">
        {/* Sidebar */}
        <div className="w-20 bg-white border-r border-gray-200 flex flex-col items-center py-6">
          <img 
            src="https://cdn.prod.website-files.com/67091f695cd8e90971357ea4/670c042366629d51d7d23454_maincolored.png"
            alt="Agilio Logo"
            className="w-12 h-12 object-contain mb-8"
          />
          <Link
            to="/proposals"
            className="w-12 h-12 flex flex-col items-center justify-center text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
          >
            <DocumentTextIcon className="w-6 h-6" />
            <span className="text-xs mt-1">Proposals</span>
          </Link>

          <div className="mt-auto">
            <button
              onClick={handleSignOut}
              className="w-12 h-12 flex flex-col items-center justify-center text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors"
              title="Sign Out"
            >
              <ArrowRightOnRectangleIcon className="w-6 h-6" />
              <span className="text-xs mt-1">Sign Out</span>
            </button>
          </div>
        </div>

        {/* Main content */}
        <div className="flex-1 overflow-auto">
          <div className="p-4 bg-white border-b border-gray-200">
            <div className="flex justify-between items-center">
              <h1 className="text-xl font-semibold text-gray-900">
                Welcome, {user?.email}
              </h1>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};