import { ApplianceItem, SystemKit, SystemTier } from '../types';
import { batterySpecs } from '../data/batterySpecs';
import { calculatePeakPower, calculateTotalAmps, calculateDailyUsage } from './powerCalculator';
import { calculateBackupHours } from './batteryCalculator';
import { calculateRequiredInverters, calculateInverterCapacity } from './inverterCalculator';
import { createSystemKit, createEmptySystemKit } from './systemKitUtils';

function generateSystemTiers(requiredInverters: number, dailyUsage: number, solarOffset: number = 0): SystemTier[] {
  const baseInverterAmps = Math.round(batterySpecs.power.continuous * 1000 / 120);
  const maxAmps = baseInverterAmps * requiredInverters;

  const calculateBackupTime = (batteryCount: number) => {
    return calculateBackupHours(dailyUsage, batteryCount, solarOffset);
  };

  const getDescription = (inverters: number, batteries: number) => 
    `${inverters} Gen2 Inverter${inverters > 1 ? 's' : ''} & ${batteries} ${batterySpecs.energyCapacity}kWh Batter${batteries > 1 ? 'ies' : 'y'}`;

  const calculatePrice = (inverters: number, batteries: number) => {
    const basePrice = batterySpecs.basePrice.inverter * inverters;
    const additionalBatteryCost = batterySpecs.basePrice.battery * (batteries - inverters);
    return basePrice + additionalBatteryCost;
  };

  const goodBatteries = requiredInverters;
  const betterBatteries = requiredInverters * 2;
  const bestBatteries = requiredInverters * 3;

  // Calculate power output based on number of batteries
  const calculatePowerOutput = (batteries: number) => batteries * batterySpecs.power.continuous;
  const calculateMaxAmps = (batteries: number) => Math.round(calculatePowerOutput(batteries) * 1000 / 120);

  return [
    {
      name: "GOOD",
      coverage: [
        "Essential Lights",
        "Wi-Fi",
        "Refrigerator",
        "Small Electronics",
        `Up to ${calculatePowerOutput(goodBatteries)}kW continuous power`,
        `${batterySpecs.energyCapacity * goodBatteries}kWh energy storage`,
        `Maximum ${calculateMaxAmps(goodBatteries)}A continuous current`
      ],
      idealFor: [
        "Essential backup",
        "Power outage protection",
        "Critical device backup"
      ],
      backupHours: calculateBackupTime(goodBatteries),
      amps: calculateMaxAmps(goodBatteries),
      batteries: goodBatteries,
      inverters: requiredInverters,
      price: calculatePrice(requiredInverters, goodBatteries),
      description: getDescription(requiredInverters, goodBatteries)
    },
    {
      name: "BETTER",
      coverage: [
        "Multiple rooms",
        "Kitchen Appliances",
        "Entertainment",
        "Small A/C",
        `Up to ${calculatePowerOutput(betterBatteries)}kW continuous power`,
        `${batterySpecs.energyCapacity * betterBatteries}kWh energy storage`,
        `Maximum ${calculateMaxAmps(betterBatteries)}A continuous current`
      ],
      idealFor: [
        "Extended power outage protection",
        "Comfortable living backup"
      ],
      backupHours: calculateBackupTime(betterBatteries),
      amps: calculateMaxAmps(betterBatteries),
      batteries: betterBatteries,
      inverters: requiredInverters,
      price: calculatePrice(requiredInverters, betterBatteries),
      description: getDescription(requiredInverters, betterBatteries)
    },
    {
      name: "BEST",
      coverage: [
        "Whole house coverage",
        "All Appliances",
        "HVAC Systems",
        "Heavy Equipment",
        `Up to ${calculatePowerOutput(bestBatteries)}kW continuous power`,
        `${batterySpecs.energyCapacity * bestBatteries}kWh energy storage`,
        `Maximum ${calculateMaxAmps(bestBatteries)}A continuous current`
      ],
      idealFor: [
        "Complete home backup",
        "Off-grid capability"
      ],
      backupHours: calculateBackupTime(bestBatteries),
      amps: calculateMaxAmps(bestBatteries),
      batteries: bestBatteries,
      inverters: requiredInverters,
      price: calculatePrice(requiredInverters, bestBatteries),
      description: getDescription(requiredInverters, bestBatteries)
    }
  ];
}

export function calculateSystemRequirements(appliances: ApplianceItem[], solarOffset: number = 0) {
  const selectedAppliances = appliances.filter(a => a.quantity > 0);
  
  if (selectedAppliances.length === 0) {
    return {
      systemKit: createEmptySystemKit(),
      dailyUsage: 0,
      totalAmps: 0
    };
  }
  
  const totalAmps = calculateTotalAmps(selectedAppliances);
  const requiredInverters = calculateRequiredInverters(totalAmps);
  const inverterCapacity = calculateInverterCapacity(requiredInverters);
  
  const dailyUsage = calculateDailyUsage(selectedAppliances);
  const systemTiers = generateSystemTiers(requiredInverters, dailyUsage, solarOffset);
  const recommendedSystem = systemTiers[1]; // BETTER option
  
  const backupHours = calculateBackupHours(
    dailyUsage,
    recommendedSystem.batteries,
    solarOffset
  );

  const systemKit = createSystemKit(
    requiredInverters,
    recommendedSystem.batteries,
    inverterCapacity,
    backupHours,
    selectedAppliances,
    systemTiers
  );

  return {
    systemKit,
    dailyUsage,
    totalAmps
  };
}