import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { fetchProposalById } from '../lib/supabase/queries/proposals/fetchProposalById';
import { CustomerInfoCard } from '../components/proposals/detail/CustomerInfoCard';
import { RequirementsCard } from '../components/proposals/detail/RequirementsCard';
import { ProposalOptionsCard } from '../components/proposals/detail/ProposalOptionsCard';
import { SelectedQuoteCard } from '../components/proposals/detail/SelectedQuoteCard';
import { AppointmentCard } from '../components/proposals/detail/AppointmentCard';
import { ActionsCard } from '../components/proposals/detail/ActionsCard';
import { calculateSystemRequirements } from '../utils/systemCalculator';
import { Proposal } from '../lib/supabase/queries/proposals/types';

export const ProposalDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [proposal, setProposal] = useState<Proposal | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!id) throw new Error('No proposal ID provided');
        
        const proposalData = await fetchProposalById(id);
        setProposal(proposalData);

      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load proposal');
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [id]);

  if (isLoading) {
    return (
      <div className="p-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-1/4"></div>
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-2 space-y-4">
              <div className="h-40 bg-gray-200 rounded"></div>
              <div className="h-60 bg-gray-200 rounded"></div>
              <div className="h-40 bg-gray-200 rounded"></div>
            </div>
            <div className="space-y-4">
              <div className="h-40 bg-gray-200 rounded"></div>
              <div className="h-40 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !proposal) {
    return (
      <div className="p-8">
        <div className="bg-red-50 rounded-lg p-4 text-red-800">
          <p className="font-medium">Error loading proposal</p>
          <p className="text-sm mt-1">{error}</p>
        </div>
      </div>
    );
  }

  // Calculate system requirements to get the system tiers
  const calculationResult = calculateSystemRequirements(
    proposal.quote_details.appliances,
    proposal.quote_details.solarDetails?.estimatedDailyProduction || 0
  );

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            Proposal Details
          </h1>
          <p className="text-gray-500 mt-1">
            Created on {format(new Date(proposal.created_at), 'MMMM d, yyyy')}
          </p>
        </div>
        <button
          onClick={() => navigate('/proposals')}
          className="px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
        >
          Back to List
        </button>
      </div>

      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-2 space-y-6">
          <CustomerInfoCard customerInfo={proposal.customer_info} />
          <RequirementsCard quoteDetails={proposal.quote_details} />
          <ProposalOptionsCard 
            systemTiers={calculationResult.systemKit.systemTiers}
            selectedTier={proposal.quote_details.selectedSystem}
          />
          <SelectedQuoteCard 
            selectedQuote={proposal.quote_details.selectedSystem}
            quoteDetails={proposal.quote_details}
            customerInfo={proposal.customer_info}
          />
        </div>

        <div className="space-y-6">
          <AppointmentCard
            appointmentDate={proposal.appointment_date}
            appointmentTime={proposal.appointment_time}
            status={proposal.status}
          />
          <ActionsCard
            proposalId={proposal.id}
            status={proposal.status}
            onEdit={() => {/* Handle edit */}}
          />
        </div>
      </div>
    </div>
  );
};