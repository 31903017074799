import React from 'react';
import { BoltIcon, ClockIcon } from '@heroicons/react/24/outline';
import { batterySpecs } from '../data/batterySpecs';

interface SystemCapacityInfoProps {
  inverterCount: number;
  batteryCount: number;
  dailyUsage: number;
  totalAmps: number;
}

export const SystemCapacityInfo: React.FC<SystemCapacityInfoProps> = ({
  inverterCount,
  batteryCount,
  dailyUsage,
  totalAmps
}) => {
  const totalCapacity = batteryCount * batterySpecs.energyCapacity;
  const maxContinuousPower = batteryCount * batterySpecs.power.continuous;
  const maxAmps = Math.round(maxContinuousPower * 1000 / 120);
  const backupHours = dailyUsage > 0 
    ? Math.round((totalCapacity * batterySpecs.inverter.roundTripEfficiency / dailyUsage) * 24)
    : 0;
  
  const backupDays = Math.floor(backupHours / 24);
  const remainingHours = backupHours % 24;
  const backupDuration = backupHours >= 24
    ? `${backupDays} day${backupDays !== 1 ? 's' : ''} ${remainingHours > 0 ? `and ${remainingHours} hour${remainingHours !== 1 ? 's' : ''}` : ''}`
    : `${backupHours} hour${backupHours !== 1 ? 's' : ''}`;

  return (
    <div className="space-y-4 mt-4 p-4 bg-gray-50 rounded-lg">
      <h4 className="font-medium text-gray-900">System Capacity</h4>
      
      <div className="space-y-3">
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-blue-50 rounded-lg p-3">
            <div className="flex items-center space-x-2 mb-1">
              <BoltIcon className="h-4 w-4 text-blue-500" />
              <span className="text-sm font-medium text-blue-900">Power Output</span>
            </div>
            <p className="text-lg font-bold text-blue-900">{maxContinuousPower} kW</p>
            <p className="text-xs text-blue-600">Continuous Power</p>
          </div>

          <div className="bg-green-50 rounded-lg p-3">
            <div className="flex items-center space-x-2 mb-1">
              <ClockIcon className="h-4 w-4 text-green-500" />
              <span className="text-sm font-medium text-green-900">Backup Duration</span>
            </div>
            <p className="text-lg font-bold text-green-900">{backupDuration}</p>
            <p className="text-xs text-green-600">At current usage</p>
          </div>
        </div>

        <div className="text-sm text-gray-600">
          <p>• Energy Storage: {totalCapacity.toFixed(1)} kWh total capacity</p>
          <p>• Amp Capacity: {totalAmps.toFixed(1)}A / {maxAmps}A ({Math.round(totalAmps/maxAmps * 100)}% utilized)</p>
          <p>• Daily Usage: {dailyUsage.toFixed(1)} kWh</p>
          <p>• Peak Power: {(maxContinuousPower * batterySpecs.power.peak / batterySpecs.power.continuous).toFixed(1)} kW</p>
          <p>• Max Solar Input: {batterySpecs.inverter.maxSolarInput * inverterCount} kW</p>
        </div>
      </div>
    </div>
  );
};