import React, { createContext, useState, useCallback, useMemo, useRef } from 'react';
import { Step, StepperContextType, StepperProviderProps } from '../types/stepper';
import { StepValidation } from '../types/validation';

export const StepperContext = createContext<StepperContextType | undefined>(undefined);

export const StepperProvider: React.FC<StepperProviderProps> = ({
  children,
  totalSteps,
  steps,
  initialStep = 0,
  onStepChange
}) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [stepValidation, setStepValidation] = useState<StepValidation>({
    isValid: false,
    errors: []
  });
  const [nextLabel, setNextLabel] = useState("Continue");
  const handleSubmit = useRef<() => Promise<void>>();

  const goToStep = useCallback((step: number) => {
    if (step >= 0 && step < totalSteps) {
      setCurrentStep(step);
      onStepChange?.(step);
    }
  }, [totalSteps, onStepChange]);

  const handleNext = useCallback(async () => {
    if (currentStep === totalSteps - 1) {
      console.log('Final step - executing submit handler');
      if (stepValidation.isValid && handleSubmit.current) {
        try {
          await handleSubmit.current();
          console.log('Submit handler completed successfully');
        } catch (error) {
          console.error('Submit handler failed:', error);
        }
      }
    } else if (currentStep < totalSteps - 1 && stepValidation.isValid) {
      goToStep(currentStep + 1);
    }
  }, [currentStep, totalSteps, stepValidation.isValid, goToStep]);

  const handleBack = useCallback(() => {
    if (currentStep > 0) {
      goToStep(currentStep - 1);
    }
  }, [currentStep, goToStep]);

  const updateStepValidation = useCallback((isValid: boolean, errors: string[] = []) => {
    setStepValidation(prev => {
      if (prev.isValid === isValid && 
          prev.errors.length === errors.length && 
          prev.errors.every((e, i) => e === errors[i])) {
        return prev;
      }
      return { isValid, errors };
    });
  }, []);

  const value = useMemo(() => ({
    currentStep,
    totalSteps,
    steps,
    stepValidation,
    setStepValidation,
    nextLabel,
    setNextLabel,
    handleNext,
    handleBack,
    handleSubmit,
    canGoBack: currentStep > 0,
    goToStep,
    isLastStep: currentStep === totalSteps - 1,
    updateStepValidation
  }), [
    currentStep,
    totalSteps,
    steps,
    stepValidation,
    nextLabel,
    handleNext,
    handleBack,
    goToStep,
    updateStepValidation
  ]);

  return (
    <StepperContext.Provider value={value}>
      {children}
    </StepperContext.Provider>
  );
};