import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useStepper } from '../../hooks/useStepper';
import { Step } from '../../types/stepper';

interface StepperHeaderProps {
  steps: Step[];
}

export const StepperHeader: React.FC<StepperHeaderProps> = ({ steps }) => {
  const { currentStep } = useStepper();

  return (
    <div className="py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <nav aria-label="Progress">
          <ol className="flex items-center justify-between w-full">
            {steps.map((step, index) => {
              const stepNumber = index + 1;
              const isActive = stepNumber === currentStep + 1;
              const isCompleted = stepNumber < currentStep + 1;

              return (
                <li key={step.name} className="relative flex-1">
                  <div className="flex items-center">
                    {/* Line connector */}
                    {index > 0 && (
                      <div 
                        className={`absolute left-0 right-1/2 -translate-y-1/2 top-1/2 h-0.5 ${
                          isCompleted ? 'bg-blue-600' : 'bg-gray-200'
                        }`} 
                        aria-hidden="true"
                      />
                    )}
                    {index < steps.length - 1 && (
                      <div 
                        className={`absolute left-1/2 right-0 -translate-y-1/2 top-1/2 h-0.5 ${
                          isCompleted ? 'bg-blue-600' : 'bg-gray-200'
                        }`} 
                        aria-hidden="true"
                      />
                    )}
                    
                    {/* Step circle */}
                    <div className="relative flex items-center justify-center">
                      <span
                        className={`w-8 h-8 flex items-center justify-center rounded-full text-sm font-medium ${
                          isCompleted
                            ? 'bg-blue-600 text-white'
                            : isActive
                            ? 'border-2 border-blue-600 text-blue-600'
                            : 'border-2 border-gray-200 text-gray-500'
                        }`}
                      >
                        {isCompleted ? (
                          <CheckIcon className="w-5 h-5" />
                        ) : (
                          stepNumber
                        )}
                      </span>
                    </div>
                  </div>
                  
                  {/* Step label */}
                  <div className="mt-2 text-center">
                    <div
                      className={`text-sm font-medium ${
                        isActive ? 'text-blue-600' : 'text-gray-900'
                      }`}
                    >
                      {step.name}
                    </div>
                    <div className="text-sm text-gray-500">{step.description}</div>
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    </div>
  );
};