import React, { useMemo } from 'react';
import { SolarEvaluation } from '../SolarEvaluation';
import { StepWrapper } from '../stepper/StepWrapper';
import { SolarDetails } from '../../types';

interface SolarStepProps {
  onComplete: (details: SolarDetails) => void;
  onBack: () => void;
}

export const SolarStep: React.FC<SolarStepProps> = ({ onComplete, onBack }) => {
  const validation = useMemo(() => ({
    isValid: true,
    errors: []
  }), []);

  return (
    <StepWrapper
      validation={validation}
      nextLabel="Continue to System Selection"
    >
      <SolarEvaluation 
        onComplete={onComplete}
        onBack={onBack}
      />
    </StepWrapper>
  );
};