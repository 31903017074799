import React from 'react';
import { StepperHeader } from './StepperHeader';
import { StepContent } from './StepContent';
import { StepperFooter } from './StepperFooter';
import { Step } from '../../types/stepper';
import { useStepper } from '../../hooks/useStepper';

interface StepperLayoutProps {
  children: React.ReactNode;
  steps: Step[];
  showPriceToggle?: boolean;
  showPreRebatePrice?: boolean;
  onPriceToggle?: (show: boolean) => void;
}

export const StepperLayout: React.FC<StepperLayoutProps> = ({
  children,
  steps,
  showPriceToggle,
  showPreRebatePrice,
  onPriceToggle
}) => {
  const { 
    currentStep, 
    handleNext, 
    handleBack, 
    stepValidation, 
    nextLabel,
    isLastStep 
  } = useStepper();

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <div className="flex-grow">
        <StepperHeader steps={steps} />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <StepContent>
            {children}
          </StepContent>
        </div>
      </div>
      <StepperFooter
        currentStep={currentStep}
        totalSteps={steps.length}
        onNext={handleNext}
        onBack={handleBack}
        isStepValid={stepValidation.isValid}
        nextLabel={nextLabel}
        showSubmit={isLastStep}
        showPriceToggle={showPriceToggle}
        showPreRebatePrice={showPreRebatePrice}
        onPriceToggle={onPriceToggle}
      />
    </div>
  );
};