export const batterySpecs = {
  energyCapacity: 14.3, // kWh per unit
  usableCapacity: 0.8, // 80% of total capacity (can't discharge below 20%)
  power: {
    continuous: 8, // kW
    peak: 14, // kW (10 seconds)
  },
  dimensions: {
    length: 74.25, // inches
    width: 18.5,
    depth: 10,
    weight: 375, // lbs
  },
  inverter: {
    efficiency: 0.96, // 96%
    roundTripEfficiency: 0.94, // 94%
    maxSolarInput: 12, // kW
  },
  temperature: {
    min: 32, // °F
    max: 86,
  },
  warranty: {
    standard: 10, // years
    extended: 25,
  },
  basePrice: {
    inverter: 15999, // MSRP for 1 inverter + 1 battery package
    battery: 5000,   // Additional battery cost
    installation: {
      inverter: 1000, // Per inverter
      battery: 800,   // Per battery
    }
  },
  packagePrices: {
    good: 15999,    // 1 inverter, 1 battery
    better: 20999,  // 1 inverter, 2 batteries
    best: 25999,    // 1 inverter, 3 batteries
  }
};