import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { LoginForm } from './components/auth/LoginForm';
import { SetPassword } from './components/auth/SetPassword';
import { AuthRedirect } from './components/auth/AuthRedirect';
import { MainLayout } from './components/layout/MainLayout';
import { ProposalList } from './components/proposals/ProposalList';
import { ProposalDetail } from './pages/ProposalDetail';
import { BatteryCalculator } from './pages/BatteryCalculator';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/auth/callback" element={<AuthRedirect />} />
          <Route path="/auth/set-password" element={<SetPassword />} />
          
          <Route path="/" element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }>
            <Route index element={<Navigate to="/proposals" replace />} />
            <Route path="proposals" element={<ProposalList />} />
            <Route path="proposals/:id" element={<ProposalDetail />} />
            <Route path="proposals/battery/new" element={<BatteryCalculator />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;