import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '../../lib/supabase/config';
import { LoadingSpinner } from './LoadingSpinner';

export const AuthRedirect: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleAuthRedirect = async () => {
      try {
        const token = searchParams.get('token');
        const type = searchParams.get('type');
        const email = searchParams.get('email');
        const error = searchParams.get('error');
        const error_description = searchParams.get('error_description');

        // Handle errors first
        if (error || error_description) {
          console.error('Auth error:', { error, error_description });
          navigate('/login');
          return;
        }

        // Handle invite flow
        if (token && type === 'invite' && email) {
          navigate(`/auth/set-password?token=${token}&type=${type}&email=${email}`);
          return;
        }

        // Check for existing session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          navigate('/login');
          return;
        }

        if (session) {
          navigate('/proposals');
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Auth redirect error:', error);
        navigate('/login');
      }
    };

    handleAuthRedirect();
  }, [navigate, searchParams]);

  return <LoadingSpinner />;
};