import React from 'react';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline';

export interface ProposalFilters {
  startDate?: Date;
  endDate?: Date;
  status?: string;
}

interface ProposalFiltersProps {
  filters: ProposalFilters;
  onFiltersChange: (filters: ProposalFilters) => void;
}

const statusOptions = [
  { value: '', label: 'All Statuses' },
  { value: 'pending', label: 'Pending' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' }
];

export const ProposalFilters: React.FC<ProposalFiltersProps> = ({
  filters,
  onFiltersChange
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  const handleDateSelect = (date: Date | undefined) => {
    if (!date) return;

    if (!filters.startDate) {
      onFiltersChange({ ...filters, startDate: date });
    } else if (!filters.endDate && date >= filters.startDate) {
      onFiltersChange({ ...filters, endDate: date });
      setIsDatePickerOpen(false);
    } else {
      onFiltersChange({ ...filters, startDate: date, endDate: undefined });
    }
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onFiltersChange({ ...filters, status: e.target.value });
  };

  const clearDateRange = () => {
    onFiltersChange({ ...filters, startDate: undefined, endDate: undefined });
    setIsDatePickerOpen(false);
  };

  const clearStatus = () => {
    onFiltersChange({ ...filters, status: undefined });
  };

  const clearAllFilters = () => {
    onFiltersChange({});
    setIsDatePickerOpen(false);
  };

  const hasActiveFilters = filters.startDate || filters.endDate || filters.status;
  const hasDateFilter = filters.startDate || filters.endDate;

  return (
    <div className="flex items-center space-x-4">
      <div className="relative">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
            className="px-4 py-2 border rounded-lg flex items-center space-x-2 hover:bg-gray-50 transition-colors"
          >
            <FunnelIcon className="h-5 w-5 text-gray-500" />
            <span className="text-gray-700">
              {filters.startDate
                ? filters.endDate
                  ? `${format(filters.startDate, 'MMM d')} - ${format(filters.endDate, 'MMM d')}`
                  : format(filters.startDate, 'MMM d')
                : 'Date Range'}
            </span>
          </button>
          {hasDateFilter && (
            <button
              onClick={clearDateRange}
              className="p-2 text-gray-400 hover:text-gray-600 transition-colors"
              title="Clear date range"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          )}
        </div>

        {isDatePickerOpen && (
          <div className="absolute top-full mt-2 bg-white rounded-lg shadow-lg border p-4 z-50">
            <DayPicker
              mode="single"
              selected={filters.endDate || filters.startDate}
              onSelect={handleDateSelect}
              footer={
                <p className="text-sm text-gray-500 mt-2">
                  {!filters.startDate
                    ? 'Select start date'
                    : !filters.endDate
                    ? 'Select end date'
                    : 'Date range selected'}
                </p>
              }
            />
          </div>
        )}
      </div>

      <div className="flex items-center space-x-2">
        <select
          value={filters.status || ''}
          onChange={handleStatusChange}
          className="px-4 py-2 border rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
        >
          {statusOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {filters.status && (
          <button
            onClick={clearStatus}
            className="p-2 text-gray-400 hover:text-gray-600 transition-colors"
            title="Clear status filter"
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        )}
      </div>

      {hasActiveFilters && (
        <button
          onClick={clearAllFilters}
          className="px-3 py-2 text-gray-500 hover:text-gray-700 transition-colors flex items-center space-x-1"
        >
          <XMarkIcon className="h-5 w-5" />
          <span>Clear All Filters</span>
        </button>
      )}
    </div>
  );
};