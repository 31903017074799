import React from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';

interface ActionsCardProps {
  proposalId: string;
  status: string;
  onEdit: () => void;
}

export const ActionsCard: React.FC<ActionsCardProps> = ({
  proposalId,
  status,
  onEdit
}) => {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">Actions</h2>
      
      <div className="space-y-3">
        <button
          onClick={onEdit}
          className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
        >
          <PencilIcon className="w-5 h-5 mr-2" />
          Edit Proposal
        </button>
      </div>
    </div>
  );
};