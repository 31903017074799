import React from 'react';
import { SystemTier } from '../../../types';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { ProposalPdfButton } from '../../ProposalPdfButton';
import { batterySpecs } from '../../../data/batterySpecs';

interface SelectedQuoteCardProps {
  selectedQuote: SystemTier;
  quoteDetails?: any;
  customerInfo?: any;
}

export const SelectedQuoteCard: React.FC<SelectedQuoteCardProps> = ({ 
  selectedQuote,
  quoteDetails,
  customerInfo
}) => {
  // Calculate installation costs
  const inverterInstallCost = selectedQuote.inverters * batterySpecs.basePrice.installation.inverter;
  const batteryInstallCost = selectedQuote.batteries * batterySpecs.basePrice.installation.battery;
  const totalInstallCost = inverterInstallCost + batteryInstallCost;

  // Calculate equipment costs
  const inverterCost = selectedQuote.inverters * batterySpecs.basePrice.inverter;
  const batteryCost = selectedQuote.batteries * batterySpecs.basePrice.battery;
  const equipmentCost = inverterCost + batteryCost;

  // Calculate totals
  const subtotal = equipmentCost + totalInstallCost;
  const taxRate = 0.08; // Example tax rate
  const taxAmount = equipmentCost * taxRate; // Only equipment is taxable
  const totalBeforeRebate = subtotal + taxAmount;
  const federalRebate = totalBeforeRebate * 0.3;
  const finalTotal = totalBeforeRebate - federalRebate;

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-start mb-6">
        <div>
          <h2 className="text-lg font-semibold text-gray-900">Selected Package</h2>
          <p className="text-gray-500">{selectedQuote.description}</p>
        </div>
        <div className="flex items-center space-x-4">
          {quoteDetails && (
            <ProposalPdfButton 
              quoteDetails={quoteDetails} 
              customerInfo={customerInfo}
            />
          )}
          <div className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm font-medium">
            Selected
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="p-4 bg-gray-50 rounded-lg">
          <p className="text-sm text-gray-500">Inverters</p>
          <p className="text-lg font-semibold text-gray-900">{selectedQuote.inverters}</p>
        </div>
        <div className="p-4 bg-gray-50 rounded-lg">
          <p className="text-sm text-gray-500">Batteries</p>
          <p className="text-lg font-semibold text-gray-900">{selectedQuote.batteries}</p>
        </div>
        <div className="p-4 bg-gray-50 rounded-lg">
          <p className="text-sm text-gray-500">Backup Hours</p>
          <p className="text-lg font-semibold text-gray-900">{selectedQuote.backupHours}h</p>
        </div>
      </div>

      {/* Line Items */}
      <div className="border rounded-lg mb-6">
        <div className="px-4 py-3 bg-gray-50 border-b">
          <h3 className="font-medium text-gray-900">Line Items</h3>
        </div>
        <div className="divide-y">
          {/* Equipment */}
          <div className="p-4">
            <div className="flex justify-between mb-2">
              <p className="text-gray-900">Gen2 Inverters ({selectedQuote.inverters}x)</p>
              <p className="text-gray-900">${inverterCost.toLocaleString()}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-900">Battery Packs ({selectedQuote.batteries}x)</p>
              <p className="text-gray-900">${batteryCost.toLocaleString()}</p>
            </div>
          </div>

          {/* Installation */}
          <div className="p-4">
            <div className="flex justify-between mb-2">
              <p className="text-gray-900">Inverter Installation</p>
              <p className="text-gray-900">${inverterInstallCost.toLocaleString()}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-900">Battery Installation</p>
              <p className="text-gray-900">${batteryInstallCost.toLocaleString()}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Totals */}
      <div className="space-y-3 border-t border-gray-200 pt-4">
        <div className="flex justify-between text-gray-600">
          <span>Equipment Subtotal</span>
          <span>${equipmentCost.toLocaleString()}</span>
        </div>
        <div className="flex justify-between text-gray-600">
          <span>Installation</span>
          <span>${totalInstallCost.toLocaleString()}</span>
        </div>
        <div className="flex justify-between text-gray-600">
          <span>Sales Tax (8%)</span>
          <span>${taxAmount.toLocaleString()}</span>
        </div>
        <div className="flex justify-between text-gray-900 font-medium">
          <span>Total Before Rebate</span>
          <span>${totalBeforeRebate.toLocaleString()}</span>
        </div>
        <div className="flex justify-between text-green-600">
          <span>Federal Tax Credit (30%)</span>
          <span>-${federalRebate.toLocaleString()}</span>
        </div>
        <div className="flex justify-between text-xl font-bold text-gray-900 pt-3 border-t">
          <span>Final Investment</span>
          <span>${finalTotal.toLocaleString()}</span>
        </div>

        {/* Financing Option */}
        <div className="mt-4 p-4 bg-blue-50 rounded-lg">
          <div className="flex justify-between items-end">
            <div>
              <p className="text-sm text-blue-700 font-medium">Monthly Payment Option</p>
              <p className="text-xs text-blue-600">60 months @ 0% APR</p>
            </div>
            <div className="text-right">
              <p className="text-2xl font-bold text-blue-700">
                ${Math.round(finalTotal / 60).toLocaleString()}/mo
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};