export const defaultAppliances = [
  // Kitchen
  { name: "Refrigerator (Energy Star)", wattHours: 150, hoursPerDay: 8, kwhPerDay: 1.2, quantity: 0, amps: 1.25 },
  { name: "Microwave", wattHours: 1100, hoursPerDay: 0.5, kwhPerDay: 0.55, quantity: 0, amps: 9.17 },
  { name: "Coffee Maker", wattHours: 900, hoursPerDay: 0.5, kwhPerDay: 0.45, quantity: 0, amps: 7.5 },
  { name: "Dishwasher (Heat Dry)", wattHours: 1800, hoursPerDay: 1.5, kwhPerDay: 2.7, quantity: 0, amps: 15 },
  
  // Living Areas
  { name: "LED Light (60W equivalent)", wattHours: 9, hoursPerDay: 5, kwhPerDay: 0.045, quantity: 0, amps: 0.075 },
  { name: "TV (55\" LED)", wattHours: 60, hoursPerDay: 4, kwhPerDay: 0.24, quantity: 0, amps: 0.5 },
  { name: "Cable/DVR Box", wattHours: 35, hoursPerDay: 24, kwhPerDay: 0.84, quantity: 0, amps: 0.29 },
  { name: "Gaming Console", wattHours: 150, hoursPerDay: 2, kwhPerDay: 0.3, quantity: 0, amps: 1.25 },
  
  // Office/Electronics
  { name: "Wi-Fi Router", wattHours: 7, hoursPerDay: 24, kwhPerDay: 0.17, quantity: 0, amps: 0.06 },
  { name: "Laptop Charger", wattHours: 65, hoursPerDay: 4, kwhPerDay: 0.26, quantity: 0, amps: 0.54 },
  { name: "Desktop PC with Monitor", wattHours: 200, hoursPerDay: 4, kwhPerDay: 0.8, quantity: 0, amps: 1.67 },
  
  // Laundry
  { name: "Washing Machine", wattHours: 500, hoursPerDay: 1, kwhPerDay: 0.5, quantity: 0, amps: 4.17 },
  { name: "Electric Dryer", wattHours: 3000, hoursPerDay: 1, kwhPerDay: 3.0, quantity: 0, amps: 25 },
  
  // HVAC
  { name: "Central AC (3-ton)", wattHours: 3500, hoursPerDay: 6, kwhPerDay: 21.0, quantity: 0, amps: 29.17 },
  { name: "Window AC (12000 BTU)", wattHours: 1200, hoursPerDay: 6, kwhPerDay: 7.2, quantity: 0, amps: 10 },
  { name: "Ceiling Fan", wattHours: 60, hoursPerDay: 8, kwhPerDay: 0.48, quantity: 0, amps: 0.5 },
  { name: "Space Heater (1500W)", wattHours: 1500, hoursPerDay: 3, kwhPerDay: 4.5, quantity: 0, amps: 12.5 },
  
  // Water & Plumbing
  { name: "Electric Water Heater", wattHours: 4500, hoursPerDay: 3, kwhPerDay: 13.5, quantity: 0, amps: 37.5 },
  { name: "Well Pump (1 HP)", wattHours: 750, hoursPerDay: 2, kwhPerDay: 1.5, quantity: 0, amps: 6.25 },
  { name: "Sump Pump (1/3 HP)", wattHours: 350, hoursPerDay: 1, kwhPerDay: 0.35, quantity: 0, amps: 2.92 },
  
  // Specialty
  { name: "Garage Door Opener", wattHours: 550, hoursPerDay: 0.1, kwhPerDay: 0.055, quantity: 0, amps: 4.58 },
  { name: "Security System", wattHours: 5, hoursPerDay: 24, kwhPerDay: 0.12, quantity: 0, amps: 0.04 },
  { name: "Dehumidifier", wattHours: 500, hoursPerDay: 8, kwhPerDay: 4.0, quantity: 0, amps: 4.17 }
];