import React from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

interface AddressAutocompleteProps {
  value: string;
  onChange: (value: string) => void;
  onAddressSelect: (place: google.maps.places.PlaceResult) => void;
  error?: string;
}

const libraries: ("places")[] = ["places"];

const inputClasses = {
  label: "block text-sm font-medium text-gray-900 mb-2",
  input: "block w-full px-4 py-3 rounded-lg border-2 shadow-sm focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition-colors text-gray-900 text-lg",
  error: "text-sm text-red-600 mt-1",
  inputError: "border-red-300 focus:border-red-500",
  inputValid: "border-gray-200 focus:border-blue-500"
};

export const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  value,
  onChange,
  onAddressSelect,
  error
}) => {
  const [autocomplete, setAutocomplete] = React.useState<google.maps.places.Autocomplete | null>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        onChange(place.formatted_address);
        onAddressSelect(place);
      }
    }
  };

  const getInputClassName = () => {
    return `${inputClasses.input} ${
      error ? inputClasses.inputError : inputClasses.inputValid
    }`;
  };

  return (
    <div>
      <label className={inputClasses.label}>
        Address <span className="text-red-500">*</span>
      </label>
      <LoadScript
        googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
        libraries={libraries}
      >
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          restrictions={{ country: "us" }}
          fields={["formatted_address", "geometry", "place_id"]}
        >
          <input
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={getInputClassName()}
            placeholder="Enter your address"
          />
        </Autocomplete>
      </LoadScript>
      {error && (
        <p className={inputClasses.error}>{error}</p>
      )}
    </div>
  );
};