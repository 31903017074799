import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { pdfStyles } from './styles';

export const TermsAndConditions: React.FC = () => (
  <View style={pdfStyles.content}>
    <Text style={pdfStyles.sectionTitle}>Terms and Conditions</Text>
    
    <View style={pdfStyles.section}>
      <Text style={pdfStyles.termsParagraph}>
        1. Proposal Validity: This proposal is valid for 30 days from the date of issuance. Prices and availability are subject to change after this period.
      </Text>
      
      <Text style={pdfStyles.termsParagraph}>
        2. Installation Requirements: Final installation costs may vary based on site-specific conditions identified during the professional site evaluation. Additional costs may apply for:
        • Electrical service upgrades
        • Additional subpanel installation
        • Extended wiring runs
        • Structural modifications
        • Permit fees and inspections
      </Text>
      
      <Text style={pdfStyles.termsParagraph}>
        3. Payment Terms: A 25% deposit is required to initiate the project. The remaining balance is due upon completion of installation and final inspection.
      </Text>
      
      <Text style={pdfStyles.termsParagraph}>
        4. Warranty: The system includes manufacturer warranties for all components:
        • Battery System: 10-year limited warranty
        • Inverters: 10-year limited warranty
        • Installation: 5-year workmanship warranty
      </Text>
      
      <Text style={pdfStyles.termsParagraph}>
        5. Permits and Approvals: All necessary permits and inspections will be obtained as required by local authorities. Customer agrees to cooperate in obtaining any required approvals.
      </Text>
      
      <Text style={pdfStyles.termsParagraph}>
        6. Installation Timeline: Typical installation takes 1-3 days once permits are approved. Timeline may vary based on complexity and local inspection schedules.
      </Text>
      
      <Text style={pdfStyles.termsParagraph}>
        7. Tax Credit Disclaimer: While we provide information about available tax credits, we do not guarantee eligibility. Consult your tax professional regarding your specific situation.
      </Text>
      
      <Text style={pdfStyles.termsParagraph}>
        8. System Performance: Actual backup duration may vary based on usage patterns, weather conditions, and system configuration.
      </Text>
      
      <Text style={pdfStyles.termsParagraph}>
        9. Maintenance: Annual maintenance is recommended to ensure optimal system performance and longevity. Maintenance plans are available separately.
      </Text>
      
      <Text style={pdfStyles.termsParagraph}>
        10. Cancellation: Customer may cancel this agreement within 3 business days of signing without penalty. Cancellation after this period may incur reasonable costs.
      </Text>
    </View>
  </View>
);